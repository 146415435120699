import { Box, Modal, Typography } from "@mui/material";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  sx?: any;
  children: React.ReactNode;
}

export default function CustomModal(props: ModalProps) {
  return (
    <Modal sx={props.sx} open={props.open} onClose={props.handleClose}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        {props.children}
      </Box>
    </Modal>
  );
}
