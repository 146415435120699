import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate, useParams } from "react-router-dom";
import CustomTableCell from "../../../../../components/elements/CustomTableCell";
import NumericFormatCustom from "../../../../../components/elements/NumericFormatCustom";
import TableHeader from "../../../../../components/elements/TableHeader";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import billsService from "../../../../services/admin/BillsService";
import { IBankAccount } from "../../../../types/Ceb/IBankAccount";
import { ICreBill } from "../../../../types/Cre/Bill/ICreBill";
import { ICreBillNewData } from "../../../../types/Cre/Bill/ICreBillNewData";
import { ICreCategory } from "../../../../types/Cre/Category/ICreCategory";
import { ICreClient } from "../../../../types/Cre/Client/ICreClient";
import { IFisInvoice } from "../../../../types/Fis/Invoice/IFisInvoice";
import { IFisInvoiceUpdateData } from "../../../../types/Fis/Invoice/IFisInvoiceUpdateData";
import { IWallet } from "../../../../types/Cre/Wallet/IWallet";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";
import { ISimpleList } from "../../../../types/Generic/ISimpleList";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import "./NewBill.css";
import CustomModal from "../../../../../components/elements/CustomModal";
import { display, width } from "@mui/system";
import NotaFiscalSaidaModal from "../../../../../components/elements/NotaFiscalSaidaModal";
import { formataDateLocal } from "../../../../utils/date-utils";
import CustomStripedTableRow from "../../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTableContainer from "../../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../../components/elements/Crud/CustomTableBody";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

const NewBill = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  //Inicia todas as propriedadese do objeto como string vazia
  const [bill, setBill] = useState<ICreBillNewData>({
    clientId: 0,
    walletId: 0,
    bankAccountId: 0,
    billDescription: "",
    billDate: new Date(),
    dueDate: new Date(),
    billSerie: "",
    billStatus: "",
    billCode: "",
    invoices: [],
    categoryId: null,
    vlrReceber: 0,
    vlrDevolucao: 0,
    vlrDesconto: 0,
    saldoDuplicata: 0,
  } as ICreBillNewData);

  const [clientsList, setClientsList] = useState<ICreClient[]>([] as ICreClient[]);

  const [walletsList, setWalletsList] = useState<IWallet[]>([] as IWallet[]);

  const [bankAccountsList, setBankAccountsList] = useState<IBankAccount[]>([] as IBankAccount[]);

  const [categoriesList, setCategoriesList] = useState<ICreCategory[]>([] as ICreCategory[]);

  const [sort, setSort] = useState<string>("p.NAME,asc");

  const [statusList, setStatusList] = useState<ISimpleList[]>([] as ISimpleList[]);

  const { selectedAccount, accounts } = useContext(GlobalContext);

  const [temModuloBankAccount, setTemModuloBankAccount] = useState<boolean>(false);

  const [inputSearchValue, setInputSearchValue] = useState("");

  const [invoice, setInvoice] = useState<IFisInvoice[]>([]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const { id } = useParams();

  const invoiceId = Number(id);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/cre/bill");
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
  }

  const handleInvoiceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    invoiceIn: IFisInvoice,
  ) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setBill((prevBill) => ({
        ...prevBill,
        invoices: [...prevBill.invoices, invoiceIn],
      }));
    } else {
      setBill((prevBill) => ({
        ...prevBill,
        invoices: prevBill.invoices.filter((invoice) => invoice.id !== invoiceIn.id),
      }));
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };

  function validateForm() {
    //Aplicar trim em todos os campos
    bill.billSerie = bill.billSerie.trim();
    bill.billCode = bill.billCode.trim();
    if (bill.billSerie === "") {
      addMessages(["Série é obrigatória"]);
      return false;
    }
    if (bill.billCode === "") {
      addMessages(["Codigo é obrigatório"]);
      return false;
    }
    if (bill.clientId <= 0) {
      addMessages(["Cliente é obrigatório"]);
      return false;
    }
    if (bill.walletId <= 0) {
      addMessages(["Carteira é obrigatória"]);
      return false;
    }
    if (bill.dueDate === undefined) {
      addMessages(["Data de Vencimento é obrigatória"]);
      return false;
    }
    if (bill.vlrReceber && bill.vlrReceber <= 0) {
      addMessages(["Valor a Receber deve ser maior que zero"]);
      return false;
    }
    if (bill.saldoDuplicata && bill.saldoDuplicata <= 0) {
      addMessages(["Saldo da Duplicata deve ser maior que zero"]);
      return false;
    }
    if (bill.vlrReceber && bill.saldoDuplicata && bill.vlrReceber < bill.saldoDuplicata) {
      addMessages(["Valor a Receber deve ser maior ou igual ao Saldo da Duplicata"]);
      return false;
    }
    if (bill.vlrDevolucao && bill.vlrDevolucao < 0) {
      addMessages(["Valor de Devolução deve ser maior ou igual a zero"]);
      return false;
    }
    if (bill.vlrDesconto && bill.vlrDesconto < 0) {
      addMessages(["Valor de Desconto deve ser maior ou igual a zero"]);
      return false;
    }

    return true;
  }
  async function handleClickSave() {
    if (!validateForm()) {
      return;
    }

    let url = `v1/cre/bill/new`;
    /*Record do backend
        @NotEmpty String name, @NotEmpty String cpfCnpj, String number, String fantasyName, String email, String phone, String mobilePhone, String address, String addressNumber, String complement, String province, String city, String state, String postalCode, String region, String municipalInscription, String stateInscription, String observations, String externalReference, String additionalEmails*/
    const dados = {
      clientId: bill.clientId,
      walletId: bill.walletId,
      bankAccountId: bill.bankAccountId,
      billDescription: bill.billDescription,
      billDate: bill.billDate,
      dueDate: bill.dueDate,
      invoices: bill.invoices.map((invoice) => invoice.id),
      billSerie: bill.billSerie,
      billStatus: bill.billStatus,
      billCode: bill.billCode,
      categoryId: bill.categoryId,
      vlrReceber: bill.vlrReceber,
      vlrDevolucao: bill.vlrDevolucao,
      vlrDesconto: bill.vlrDesconto,
      saldoDuplicata: bill.saldoDuplicata,
    };
    console.log("dados");
    console.log(dados);
    try {
      const response = await sendPost<ICreBill>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/cre/bill");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchClients(clientName: string) {
    let url = `v1/client/list?size=10&page=0&sort=NAME,asc`;
    const dados = { clientName: clientName };
    try {
      const response = await sendPost<IPaginatedList<ICreClient>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setClientsList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchWallets(walletName: string) {
    let url = `v1/wallet/list?size=10&page=0&sort=WALLET_NAME,asc`;
    const dados = { walletName: walletName };
    try {
      const response = await sendPost<IPaginatedList<IWallet>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setWalletsList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchCategories(categoryName: string) {
    let url = `v1/cre/category/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: categoryName };
    try {
      const response = await sendPost<IPaginatedList<ICreCategory>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setCategoriesList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchBankAccount(bankAccountName: string) {
    let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: bankAccountName };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setBankAccountsList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    valor = String(valor);

    setBill((prevBill) => ({ ...prevBill, [name]: valor }));
  };

  const handleValueChange = (event: { target: { name: string; value: string } }) => {
    console.log("EventoTOP: ", event);
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setBill((prevBill) => ({ ...prevBill, [name]: valor }));
  };

  async function searchReceiptsStatusList() {
    const response = await billsService.searchReceiptsStatusList(
      addMessages,
      setaLoading,
      navigate,
    );
    if (response) {
      setStatusList(response);
      // Defina "Aberto" como padrão
      const defaultStatus = response.find((status) => status.label === "Aberto");
      if (defaultStatus) {
        setBill((prevBill) => ({ ...prevBill, billStatus: defaultStatus.label }));
      }
    } else {
      addMessages(["Não houve resposta do serviço de faturas"]);
    }
  }

  const handleClickDeleteInvoice = (invoiceToDelete: IFisInvoice) => {
    if (confirm(`Tem certeza que deseja excluir a nota`)) {
      setBill((prevBill) => {
        return {
          ...prevBill,
          invoices: prevBill.invoices.filter((invoice) => invoice.id !== invoiceToDelete.id),
        };
      });
    }
    return;
  };

  useEffect(() => {
    setaLoading(true);
    searchClients("");
    searchWallets("");
    searchCategories("");
    searchReceiptsStatusList();
    searchBankAccount("");
    const account = accounts.find((account) => account.accountId.toString() === selectedAccount);
    setTemModuloBankAccount(false);
    if (account) {
      const modulesToCheck = ["Caixas e Bancos"];
      const modules = account.modules;
      console.log(modules);
      if (modules.some((module) => modulesToCheck.includes(module.name))) {
        setTemModuloBankAccount(true);
      } else {
        console.log(`Módulo bankAccount não encontrado com o selectedAccount ${selectedAccount}`);
      }
    } else {
      console.log(`Nenhuma conta encontrada com o selectedAccount ${selectedAccount}`);
    }
    setaLoading(false);
    setaLoading(false);
  }, []);

  const columns = [
    { displayName: "Nome do emitente", columnName: null },
    { displayName: "Nome do destinatário", columnName: null },
    { displayName: "Série", columnName: null },
    { displayName: "Numero", columnName: null },
    { displayName: "Data", columnName: null },
    { displayName: "Valor", columnName: null },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Conta a receber</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-client"
                  options={clientsList}
                  getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do cliente como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      clientId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Cliente" />}
                  onInputChange={(event, newInputValue) => {
                    searchClients(newInputValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-status"
                  options={statusList}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option.label} // Use o nome da categoria como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={
                    statusList.find((billStatus) => billStatus.label === bill.billStatus) || null
                  }
                  renderInput={(params) => <TextField {...params} label="Status" required />}
                  defaultValue={statusList[0]}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      billStatus: newValue?.label ? newValue.label : "",
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-wallet"
                  options={walletsList}
                  getOptionLabel={(option) =>
                    option.walletNumber
                      ? option.walletNumber + " - " + option.walletName
                      : option.walletName
                  } // Use o nome do cliente como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      walletId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Carteira" required />}
                  onInputChange={(event, newInputValue) => {
                    searchWallets(newInputValue);
                  }}
                />
              </Grid>
              {temModuloBankAccount && (
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-bankAccount"
                    options={bankAccountsList}
                    getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                      setBill((prevBill) => ({
                        ...prevBill,
                        bankAccountId: newValue ? newValue.id : 0,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Conta Bancária" />}
                    onInputChange={(event, newInputValue) => {
                      searchBankAccount(newInputValue);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="billDescription"
                  onChange={handleChange}
                  value={bill.billDescription}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Série"
                  name="billSerie"
                  onChange={handleChange}
                  required
                  value={bill.billSerie}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Codigo"
                  name="billCode"
                  onChange={handleChange}
                  required
                  value={bill.billCode}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Data fatura"
                  value={bill.billDate}
                  onChange={(newValue: Date | null) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      billDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="billDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Vencimento fatura"
                  value={bill.dueDate}
                  onChange={(newValue: Date | null) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      dueDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="dueDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-wallet"
                  options={categoriesList}
                  getOptionLabel={(option) =>
                    (option.upperCategoryName ? option.upperCategoryName + " -> " : "") +
                    option.name
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      categoryId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Categoria" required />}
                  onInputChange={(event, newInputValue) => {
                    searchCategories(newInputValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor a Receber"
                  value={bill.vlrReceber}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      vlrReceber: Number(values.target.value),
                    }))
                  }
                  name="vlrReceber"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor Desconto"
                  value={bill.vlrDesconto}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      vlrDesconto: Number(values.target.value),
                    }))
                  }
                  name="vlrDesconto"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor da Devolução"
                  value={bill.vlrDevolucao}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      vlrDevolucao: Number(values.target.value),
                    }))
                  }
                  name="vlrDevolucao"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Saldo da Duplicata"
                  value={bill.saldoDuplicata}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      saldoDuplicata: Number(values.target.value),
                    }))
                  }
                  name="saldoDuplicata"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  sx={{ display: "flex", alignItems: "end" }}
                >
                  <Grid item>
                    <NotaFiscalSaidaModal handleChange={handleInvoiceChange} />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <CustomTable>
                      <TableHeader
                        initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                        initialSortedField={sort.split(",")[0]}
                        columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                        handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                      />
                      <CustomTableBody>
                        {bill.invoices &&
                          bill.invoices.map((row, index) => (
                            <CustomStripedTableRow key={row.id} index={index} row={row}>
                              <CustomTableCell>{row.emitterName}</CustomTableCell>
                              <CustomTableCell>{row.receiverName}</CustomTableCell>
                              <CustomTableCell>{row.invoiceSerie}</CustomTableCell>
                              <CustomTableCell>{row.invoiceNumber}</CustomTableCell>
                              <CustomTableCell>{formataDateLocal(row.invoiceDate)}</CustomTableCell>
                              <CustomTableCell>{`R$ ${Number(row.invoiceValue).toLocaleString(
                                "pt-BR",
                              )}`}</CustomTableCell>
                              <CustomTableCell>
                                <IconButton
                                  sx={{ padding: "0px 4px 0px 0px" }}
                                  onClick={() => handleClickDeleteInvoice(row)}
                                >
                                  <Tooltip title="Excluir">
                                    <SvgIcon fontSize="small">
                                      <TrashIcon aria-label="Excluir" style={iconStyles} />
                                    </SvgIcon>
                                  </Tooltip>
                                </IconButton>
                              </CustomTableCell>
                            </CustomStripedTableRow>
                          ))}
                      </CustomTableBody>
                    </CustomTable>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={handleClickSave}
                variant="contained"
                color="primary"
                fullWidth
                startIcon={
                  <SvgIcon fontSize="small">
                    <CloudArrowUpIcon />
                  </SvgIcon>
                }
              >
                Salvar
              </Button>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default NewBill;
