import React, { useContext, useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IInvoice } from "../../../../types/Generic/IInvoice";
import { IInvoiceUpdateData } from "../../../../types/Generic/IInvoiceUpdateData";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import "./EditInvoice.css";
import NumericFormatCustom from "../../../../../components/elements/NumericFormatCustom";
import { IPeople } from "../../../../types/Adm/People/IPeople";
import { IFisInvoiceUpdateData } from "../../../../types/Fis/Invoice/IFisInvoiceUpdateData";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";

const EditInvoice = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [emitterList, setEmitterList] = useState<IPeople[]>([]);

  const [senderList, setSenderList] = useState<IPeople[]>([] as IPeople[]);

  const [receiverList, setReceiverList] = useState<IPeople[]>([]);

  const { id } = useParams();
  const invoiceId = Number(id);

  const [invoice, setInvoice] = useState<IFisInvoiceUpdateData>({
    id: 0,
    emitterId: 0,
    emitterName: "",
    senderId: 0,
    senderName: "",
    receiverId: 0,
    receiverName: "",
    invoiceSerie: "",
    invoiceNumber: "",
    invoiceDate: new Date(),
    invoiceValue: 0,
  } as IFisInvoiceUpdateData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/fis/invoice");
  }

  function validateForm() {
    if (invoice.senderId === invoice.receiverId) {
      addMessages(["Remetente e Destinatário não podem ser iguais"]);
      return false;
    }
    return true;
  }

  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }
    let url = `v1/fis/invoice/edit/${invoiceId}`;
    const dados = invoice;
    try {
      const response = await sendPost<IInvoice>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/fis/invoice");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setInvoice((prevInvoice) => ({ ...prevInvoice, [name]: valor }));
  };

  async function searchEmitter(peopleName: string, peopleId: number) {
    let url = `v1/admin/people/list?size=10&page=0&sort=NAME,asc`;
    const dados = { peopleName: peopleName, peopleId: peopleId };
    try {
      const response = await sendPost<IPaginatedList<IPeople>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setEmitterList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchSender(peopleName: string, peopleId: number) {
    let url = `v1/admin/people/list?size=10&page=0&sort=NAME,asc`;
    const dados = { peopleName: peopleName, peopleId: peopleId };
    try {
      const response = await sendPost<IPaginatedList<IPeople>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setReceiverList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchReceiver(peopleName: string, peopleId: number) {
    let url = `v1/admin/people/list?size=10&page=0&sort=NAME,asc`;
    const dados = { peopleName: peopleName, peopleId: peopleId };
    try {
      const response = await sendPost<IPaginatedList<IPeople>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setReceiverList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaInvoice() {
    let url = `v1/fis/invoice/${invoiceId}`;
    const dados = {};
    try {
      const response = await sendGet<IInvoice>(url, dados, addMessages, setaLoading, navigate);
      setInvoice(response);
      searchEmitter(response.emitterName, response.emitterId);
      searchReceiver(response.receiverName, response.receiverId);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaInvoice();
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Nota</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-emitter"
                  options={emitterList}
                  getOptionLabel={(option) => option.name} // Use o nome do emmiter como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={emitterList.find((emitter) => emitter.id === invoice.emitterId) || null} // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      emitterId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Emitente" required />}
                  onInputChange={(event, newInputValue) => {
                    searchEmitter(newInputValue, invoice.emitterId);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-sender"
                  options={senderList}
                  getOptionLabel={(option) => option.name} // Use o nome do emmiter como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={senderList.find((sender) => sender.id === invoice.senderId) || null} // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      senderId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Remetente" required />}
                  onInputChange={(event, newInputValue) => {
                    searchReceiver(newInputValue, invoice.senderId);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-receiver"
                  options={receiverList}
                  getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={
                    receiverList.find((receiver) => receiver.id === invoice.receiverId) || null
                  } // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      receiverId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Destinatário" required />}
                  onInputChange={(event, newInputValue) => {
                    searchReceiver(newInputValue, invoice.receiverId);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Serie"
                  value={invoice.invoiceSerie}
                  name="invoiceSerie"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero"
                  value={invoice.invoiceNumber}
                  name="invoiceNumber"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Data Nota"
                  value={invoice.invoiceDate}
                  onChange={(newValue: Date | null) => {
                    setInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      invoiceDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 300 }} name="invoiceDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor"
                  value={invoice.invoiceValue}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setInvoice((prevInvoice) => ({
                      ...prevInvoice,
                      invoiceValue: Number(values.target.value),
                    }))
                  }
                  name="invoiceValue"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EditInvoice;
