import { Table } from "@mui/material";

interface CustomTableProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export default function CustomTable(props: CustomTableProps) {
  return (
    <Table size="small" style={props.style}>
      {props.children}
    </Table>
  );
}
