import { Paper, TableContainer } from "@mui/material";

interface CustomTableContainerProps {
  children?: React.ReactNode;
  sx?: any;
  style?: any;
}

export default function CustomTableContainer(props: CustomTableContainerProps) {
  return (
    <TableContainer sx={props.sx} style={props.style} component={Paper}>
      {props.children}
    </TableContainer>
  );
}
