import { NavigateFunction } from "react-router-dom";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IPlan } from "../../../types/Adm/Plan/IPlan";
import { sendGet, sendPost } from "../../../utils/httpUtils";
import { IUserConfig } from "../../../types/Config/IUserConfig";
import { IConfigs } from "../../../types/Config/IConfigs";
import { IAccountConfig } from "../../../types/Config/IAccountConfig";
import { IUpdateConfig } from "../../../types/Config/IUpdateConfig";
import { get } from "http";

const configService = {
  getAccountWppQrcode: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let retorno = "";
    let url = `v1/config/wpp/qrcode`;
    const dados = {};
    try {
      const response = await sendGet<string>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      retorno = response; 
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      
    }
    return retorno;
  },
  searchConfigs: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    const retorno:IConfigs = { account: [], user: [] };
    let url = `v2/config/user/list`;
    const dados = {};
    try {
      const response = await sendGet<IUserConfig[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      retorno.user = response; 
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return retorno;
    }

    url = `v2/config/account/list`;
    try {
      const response = await sendGet<IAccountConfig[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      retorno.account = response; 
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return retorno;
    }
    return retorno;
  },
  updateAccountConfig: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    updateConfig: IUpdateConfig
  ) {
    let url = `v2/config/account/update`;
    const dados = updateConfig;
    try {
      const response = await sendPost<IAccountConfig>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response; 
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return null;
    }
  },
  updateUserConfig: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    updateConfig: IUpdateConfig
  ) {
    let url = `v2/config/user/update`;
    const dados = updateConfig;
    try {
      const response = await sendPost<IUserConfig>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response; 
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return null;
    }
  },
 /*
 WPP_CONNECTED_NUMBER(
                        "WPP_CONNECTED_NUMBER",
                        "Número do telefone WhatsApp atualmente conectado",
                        List.of(1, 3, 5, 7),
                        null,
                        null,
                        "",
                        null,
                        null,
                        null,
                        null),
        WPP_CURRENT_QRCODE(
                        "WPP_CURRENT_QRCODE",
                        "Qrcode atual para conexão com WhatsApp",
                        List.of(1, 3, 5, 7),
                        null,
                        null,
                        "",
                        null,
                        null,
                        null,
                        null),
                        */
  getAccountWppDefaultSendPermittedString: function () {
    return "WPP_DEFAULT_SEND_PERMITTED";
  },
  getAccountCreWppClosureDefaultMessageString: function () {
    return "CRE_WPP_CLOSURE_DEFAULT_MESSAGE";
  },
  getAccountCreWppClosureBillMessageString: function () {
    return "CRE_WPP_CLOSURE_BILL_MESSAGE";
  },
  getAccountWppServiceString: function () {
    return "WPP_SERVICE";
  },
  getAccountWppInstanceIdString: function () {
    return "WPP_INSTANCE_ID";
  },
  getAccountWppInstanceTokenString: function () {
    return "WPP_INSTANCE_TOKEN";
  },
  getAccountWppInstanceStatusString: function () {
    return "WPP_INSTANCE_STATUS";
  },
  getAccountWppConnectedNumberString: function () {
    return "WPP_CONNECTED_NUMBER";
  },
  getAccountWppCurrentQrcodeString: function () {
    return "WPP_CURRENT_QRCODE";
  },
  getAccountAsaasApiUrlString: function () {
    return "ASAAS_API_URL";
  },
  getAccountAsaasApiKeyString: function () {
    return "ASAAS_API_KEY";
  },
  getAccountAsaasWalletIdString: function () {
    return "ASAAS_WALLET_ID";
  },
  getAccountAsaasWebhookTokenString: function () {
    return "ASAAS_WEBHOOK_TOKEN";
  },
  getAccountAsaasIdServiceInvoiceString: function () {
    return "ASAAS_ID_SERVICE_INVOICE";
  },
  getAccountAsaasAutoSendBillString: function () {
    return "ASAAS_AUTO_SEND_BILL";
  },
  updateAccountWppClosureDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountCreWppClosureDefaultMessageString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppClosureBillMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountCreWppClosureBillMessageString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppService: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    service: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppServiceString(),
      configValue: null,
      configInteger: null,
      configText: service,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppInstanceId: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    instanceId: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppInstanceIdString(),
      configValue: null,
      configInteger: null,
      configText: instanceId,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppInstanceToken: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    instanceToken: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppInstanceTokenString(),
      configValue: null,
      configInteger: null,
      configText: instanceToken,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppInstanceStatus: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    status: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppInstanceStatusString(),
      configValue: null,
      configInteger: null,
      configText: status,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppDefaultSendPermitted: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppDefaultSendPermittedString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppConnectedNumber: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    number: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppConnectedNumberString(),
      configValue: null,
      configInteger: null,
      configText: number,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountWppCurrentQrcode: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    qrcode: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountWppCurrentQrcodeString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: qrcode,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountAsaasApiUrl: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    url: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountAsaasApiUrlString(),
      configValue: null,
      configInteger: null,
      configText: url,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountAsaasApiKey: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    key: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountAsaasApiKeyString(),
      configValue: null,
      configInteger: null,
      configText: key,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountAsaasWalletId: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    walletId: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountAsaasWalletIdString(),
      configValue: null,
      configInteger: null,
      configText: walletId,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountAsaasWebhookToken: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    token: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountAsaasWebhookTokenString(),
      configValue: null,
      configInteger: null,
      configText: token,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountAsaasIdServiceInvoice: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    idServiceInvoice: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountAsaasIdServiceInvoiceString(),
      configValue: null,
      configInteger: null,
      configText: idServiceInvoice,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountAsaasAutoSendBill: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getAccountAsaasAutoSendBillString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  getAccountWppClosureDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreWppClosureDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountWppClosureBillMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreWppClosureBillMessageString());
    return config?.configMediumBlob;
  },
  getAccountWppServiceValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppServiceString());
    return config?.configText;
  },
  getAccountWppInstanceIdValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppInstanceIdString());
    return config?.configText;
  },
  getAccountWppInstanceTokenValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppInstanceTokenString());
    return config?.configText;
  },
  getAccountWppInstanceStatusValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppInstanceStatusString());
    return config?.configText;
  },
  getAccountWppDefaultSendPermittedValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppDefaultSendPermittedString());
    return config?.configBoolean;
  },
  getAccountWppConnectedNumberValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppConnectedNumberString());
    return config?.configText;
  },
  getAccountWppCurrentQrcodeValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountWppCurrentQrcodeString());
    return config?.configMediumBlob;
  },
  getAccountAsaasApiUrlValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountAsaasApiUrlString());
    return config?.configText;
  },
  getAccountAsaasApiKeyValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountAsaasApiKeyString());
    return config?.configText;
  },
  getAccountAsaasWalletIdValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountAsaasWalletIdString());
    return config?.configText;
  },
  getAccountAsaasWebhookTokenValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountAsaasWebhookTokenString());
    return config?.configText;
  },
  getAccountAsaasIdServiceInvoiceValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountAsaasIdServiceInvoiceString());
    return config?.configText;
  },
  getAccountAsaasAutoSendBillValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountAsaasAutoSendBillString());
    return config?.configBoolean;
  },
  getAccountCreDueTodayNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreDueTodayNotifEmailClientBoolString());
    return config?.configBoolean;
  },
  getAccountCreDueTodayNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreDueTodayNotifWppClientBoolString());
    return config?.configBoolean;
  },
  getAccountCreDueTomorrowNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreDueTomorrowNotifEmailClientBoolString());
    return config?.configBoolean;
  },
  getAccountCreDueTomorrowNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreDueTomorrowNotifWppClientBoolString());
    return config?.configBoolean;
  },
  getAccountCreOverdueNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreOverdueNotifEmailClientBoolString());
    return config?.configBoolean;
  },
  getAccountCreOverdueNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreOverdueNotifWppClientBoolString());
    return config?.configBoolean;
  },
  getAccountCreBillNotDueDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreBillNotDueDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountCreBillNotDueInvoiceDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreBillNotDueInvoiceDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountCreBillDueTodayDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreBillDueTodayDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountCreBillDueTodayInvoiceDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreBillDueTodayInvoiceDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountCreBillOverdueDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreBillOverdueDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountCreBillOverdueInvoiceDefaultMessageValue: function (configs: IConfigs) {
    const config = configs.account.find((config) => config.configName === configService.getAccountCreBillOverdueInvoiceDefaultMessageString());
    return config?.configMediumBlob;
  },
  getAccountCreDueTodayNotifEmailClientBoolString: function () {
    return "CRE_DUE_TODAY_NOTIF_EMAIL_CLIENT_BOOL";
  },
  getAccountCreDueTodayNotifWppClientBoolString: function () {
    return "CRE_DUE_TODAY_NOTIF_WPP_CLIENT_BOOL";
  },
  getAccountCreDueTomorrowNotifEmailClientBoolString: function () {
    return "CRE_DUE_TOMORROW_NOTIF_EMAIL_CLIENT_BOOL";
  },
  getAccountCreDueTomorrowNotifWppClientBoolString: function () {
    return "CRE_DUE_TOMORROW_NOTIF_WPP_CLIENT_BOOL";
  },
  getAccountCreOverdueNotifEmailClientBoolString: function () {
    return "CRE_OVERDUE_NOTIF_EMAIL_CLIENT_BOOL";
  },
  getAccountCreOverdueNotifWppClientBoolString: function () {
    return "CRE_OVERDUE_NOTIF_WPP_CLIENT_BOOL";
  },
  getAccountCreBillNotDueDefaultMessageString: function () {
    return "CRE_BILL_NOT_DUE_DEFAULT_MESSAGE";
  },
  getAccountCreBillNotDueInvoiceDefaultMessageString: function () {
    return "CRE_BILL_NOT_DUE_INVOICE_DEFAULT_MESSAGE";
  },
  getAccountCreBillDueTodayDefaultMessageString: function () {
    return "CRE_BILL_DUE_TODAY_DEFAULT_MESSAGE";
  },
  getAccountCreBillDueTodayInvoiceDefaultMessageString: function () {
    return "CRE_BILL_DUE_TODAY_INVOICE_DEFAULT_MESSAGE";
  },
  getAccountCreBillOverdueDefaultMessageString: function () {
    return "CRE_BILL_OVERDUE_DEFAULT_MESSAGE";
  },
  getAccountCreBillOverdueInvoiceDefaultMessageString: function () {
    return "CRE_BILL_OVERDUE_INVOICE_DEFAULT_MESSAGE";
  },
  getUserCapDueTodayNotifEmailBoolString: function () {
    return "CAP_DUE_TODAY_NOTIF_EMAIL_BOOL";
  },
  getUserCapDueTodayNotifWppBoolString: function () {
    return "CAP_DUE_TODAY_NOTIF_WPP_BOOL";
  },
  getUserCapDueTodayNotifPushBoolString: function () {
    return "CAP_DUE_TODAY_NOTIF_PUSH_BOOL";
  },
  getUserCapDueTodayNotifWebBoolString: function () {
    return "CAP_DUE_TODAY_NOTIF_WEB_BOOL";
  },
  getUserCapDueTomorrowNotifEmailBoolString: function () {
    return "CAP_DUE_TOMORROW_NOTIF_EMAIL_BOOL";
  },
  getUserCapDueTomorrowNotifWppBoolString: function () {
    return "CAP_DUE_TOMORROW_NOTIF_WPP_BOOL";
  },
  getUserCapDueTomorrowNotifPushBoolString: function () {
    return "CAP_DUE_TOMORROW_NOTIF_PUSH_BOOL";
  },
  getUserCapDueTomorrowNotifWebBoolString: function () {
    return "CAP_DUE_TOMORROW_NOTIF_WEB_BOOL";
  },
  getUserCapOverdueNotifEmailBoolString: function () {
    return "CAP_OVERDUE_NOTIF_EMAIL_BOOL";
  },
  getUserCapOverdueNotifWppBoolString: function () {
    return "CAP_OVERDUE_NOTIF_WPP_BOOL";
  },
  getUserCapOverdueNotifPushBoolString: function () {
    return "CAP_OVERDUE_NOTIF_PUSH_BOOL";
  },
  getUserCapOverdueNotifWebBoolString: function () {
    return "CAP_OVERDUE_NOTIF_WEB_BOOL";
  },
  getUserCreDueTodayNotifEmailBoolString: function () {
    return "CRE_DUE_TODAY_NOTIF_EMAIL_BOOL";
  },
  getUserCreDueTodayNotifWppBoolString: function () {
    return "CRE_DUE_TODAY_NOTIF_WPP_BOOL";
  },
  getUserCreDueTodayNotifPushBoolString: function () {
    return "CRE_DUE_TODAY_NOTIF_PUSH_BOOL";
  },
  getUserCreDueTodayNotifWebBoolString: function () {
    return "CRE_DUE_TODAY_NOTIF_WEB_BOOL";
  },
  getUserCreDueTomorrowNotifEmailBoolString: function () {
    return "CRE_DUE_TOMORROW_NOTIF_EMAIL_BOOL";
  },
  getUserCreDueTomorrowNotifWppBoolString: function () {
    return "CRE_DUE_TOMORROW_NOTIF_WPP_BOOL";
  },
  getUserCreDueTomorrowNotifPushBoolString: function () {
    return "CRE_DUE_TOMORROW_NOTIF_PUSH_BOOL";
  },
  getUserCreDueTomorrowNotifWebBoolString: function () {
    return "CRE_DUE_TOMORROW_NOTIF_WEB_BOOL";
  },
  getUserCreOverdueNotifEmailBoolString: function () {
    return "CRE_OVERDUE_NOTIF_EMAIL_BOOL";
  },
  getUserCreOverdueNotifWppBoolString: function () {
    return "CRE_OVERDUE_NOTIF_WPP_BOOL";
  },
  getUserCreOverdueNotifPushBoolString: function () {
    return "CRE_OVERDUE_NOTIF_PUSH_BOOL";
  },
  getUserCreOverdueNotifWebBoolString: function () {
    return "CRE_OVERDUE_NOTIF_WEB_BOOL";
  },
  updateUserCapDueTodayNotifEmailBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTodayNotifEmailBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTodayNotifWppBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTodayNotifWppBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTodayNotifPushBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTodayNotifPushBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTodayNotifWebBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTodayNotifWebBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTomorrowNotifEmailBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTomorrowNotifEmailBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTomorrowNotifWppBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTomorrowNotifWppBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTomorrowNotifPushBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTomorrowNotifPushBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapDueTomorrowNotifWebBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapDueTomorrowNotifWebBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapOverdueNotifEmailBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapOverdueNotifEmailBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapOverdueNotifWppBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapOverdueNotifWppBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapOverdueNotifPushBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapOverdueNotifPushBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCapOverdueNotifWebBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCapOverdueNotifWebBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTodayNotifEmailBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTodayNotifEmailBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTodayNotifWppBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTodayNotifWppBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTodayNotifPushBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTodayNotifPushBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTodayNotifWebBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTodayNotifWebBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTomorrowNotifEmailBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTomorrowNotifEmailBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTomorrowNotifWppBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTomorrowNotifWppBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTomorrowNotifPushBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTomorrowNotifPushBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreDueTomorrowNotifWebBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreDueTomorrowNotifWebBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreOverdueNotifEmailBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreOverdueNotifEmailBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreOverdueNotifWppBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreOverdueNotifWppBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreOverdueNotifPushBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreOverdueNotifPushBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateUserCreOverdueNotifWebBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: configService.getUserCreOverdueNotifWebBoolString(),
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateUserConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreDueTodayNotifEmailClientBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_DUE_TODAY_NOTIF_EMAIL_CLIENT_BOOL",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreDueTodayNotifWppClientBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_DUE_TODAY_NOTIF_WPP_CLIENT_BOOL",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreDueTomorrowNotifEmailClientBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_DUE_TOMORROW_NOTIF_EMAIL_CLIENT_BOOL",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreDueTomorrowNotifWppClientBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_DUE_TOMORROW_NOTIF_WPP_CLIENT_BOOL",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreOverdueNotifEmailClientBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_OVERDUE_NOTIF_EMAIL_CLIENT_BOOL",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreOverdueNotifWppClientBool: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    checked: boolean
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_OVERDUE_NOTIF_WPP_CLIENT_BOOL",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: checked,
      configMediumBlob: null,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreBillNotDueDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_BILL_NOT_DUE_DEFAULT_MESSAGE",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreBillNotDueInvoiceDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_BILL_NOT_DUE_INVOICE_DEFAULT_MESSAGE",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreBillDueTodayDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_BILL_DUE_TODAY_DEFAULT_MESSAGE",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreBillDueTodayInvoiceDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_BILL_DUE_TODAY_INVOICE_DEFAULT_MESSAGE",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreBillOverdueDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_BILL_OVERDUE_DEFAULT_MESSAGE",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  updateAccountCreBillOverdueInvoiceDefaultMessage: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    message: string
  ) {
    const updateConfig: IUpdateConfig = {
      configName: "CRE_BILL_OVERDUE_INVOICE_DEFAULT_MESSAGE",
      configValue: null,
      configInteger: null,
      configText: null,
      configDate: null,
      configDatetime: null,
      configBoolean: null,
      configMediumBlob: message,
    };
    const response = await configService.updateAccountConfig(addMessages, setaLoading, navigate, updateConfig);
    return response;
  },
  getUserCapDueTodayNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTodayNotifEmailBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTodayNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTodayNotifWppBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTodayNotifPushBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTodayNotifPushBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTodayNotifWebBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTodayNotifWebBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTomorrowNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTomorrowNotifEmailBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTomorrowNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTomorrowNotifWppBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTomorrowNotifPushBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTomorrowNotifPushBoolString());
    return config?.configBoolean;
  },
  getUserCapDueTomorrowNotifWebBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapDueTomorrowNotifWebBoolString());
    return config?.configBoolean;
  },
  getUserCapOverdueNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapOverdueNotifEmailBoolString());
    return config?.configBoolean;
  },
  getUserCapOverdueNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapOverdueNotifWppBoolString());
    return config?.configBoolean;
  },
  getUserCapOverdueNotifPushBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapOverdueNotifPushBoolString());
    return config?.configBoolean;
  },
  getUserCapOverdueNotifWebBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCapOverdueNotifWebBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTodayNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTodayNotifEmailBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTodayNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTodayNotifWppBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTodayNotifPushBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTodayNotifPushBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTodayNotifWebBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTodayNotifWebBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTomorrowNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTomorrowNotifEmailBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTomorrowNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTomorrowNotifWppBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTomorrowNotifPushBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTomorrowNotifPushBoolString());
    return config?.configBoolean;
  },
  getUserCreDueTomorrowNotifWebBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreDueTomorrowNotifWebBoolString());
    return config?.configBoolean;
  },
  getUserCreOverdueNotifEmailBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreOverdueNotifEmailBoolString());
    return config?.configBoolean;
  },
  getUserCreOverdueNotifWppBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreOverdueNotifWppBoolString());
    return config?.configBoolean;
  },
  getUserCreOverdueNotifPushBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreOverdueNotifPushBoolString());
    return config?.configBoolean;
  },
  getUserCreOverdueNotifWebBoolValue: function (configs: IConfigs) {
    const config = configs.user.find((config) => config.configName === configService.getUserCreOverdueNotifWebBoolString());
    return config?.configBoolean;
  },
};

export default configService;

