import React, { useCallback, useContext, useEffect, useState } from "react";

import "./Invoice.css";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import { Box, Button, Container, Stack, SvgIcon, TextField, styled } from "@mui/material";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import Paginacao from "../../../../components/elements/Paginacao";
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import invoiceService from "../../../services/admin/InvoiceService";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { formataDateLocal } from "../../../utils/date-utils";
import { sendDelete } from "../../../utils/httpUtils";
import { IInvoice } from "../../../types/Generic/IInvoice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Invoice = () => {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [inputSearchValue, setInputSearchValue] = useState("");

  const [invoice, setInvoice] = useState<IInvoice[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("i.INVOICE_DATE,desc");

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const handleItemsPerPageChange = (itens: number) => {
    searchInvoice(0, itens, sort, debouncedSearchValue);
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchInvoice(
    page: number,
    itensPerPage: number,
    sort?: string,
    searchQuery?: string,
    initialInvoiceDate?: Date,
    finalInvoiceDate?: Date,
    emitterId?: number,
    receiverId?: number,
    peopleId?: number,
    invoiceSerie?: string,
    invoiceNumber?: string,
    invoiceType?: string,
  ) {
    setItensPerPage(itensPerPage);
    const response = await invoiceService.searchFilteredInvoice(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      emitterId,
      receiverId,
      peopleId,
      invoiceSerie,
      invoiceNumber,
      invoiceType,
      searchQuery,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setInvoice(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setInvoice([]);
      addMessages(["Não houve resposta do serviço de notas"]);
    }
  }

  async function excluiInvoice(id: number) {
    let url = `/v1/fis/invoice/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchInvoice(0, itensPerPage, sort, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
      console.log("Debounced value:", value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFez) {
        searchInvoice(currentPage - 1, itensPerPage, sort, value);
      }
      jaFez = true;

      return;
    }, 1000),
    [],
  ); // 1000 ms de delay

  useEffect(() => {
    searchInvoice(0, itensPerPage, sort, debouncedSearchValue);
  }, []);

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchInvoice(page - 1, itensPerPage, sort, debouncedSearchValue);
  };

  function handleClickEditar(invoice: IInvoice): void {
    navigate(`/area-logada/fis/edit-invoice/${invoice.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/fis/new-invoice`);
  }

  function handleClickExcluir(
    invoice: IInvoice,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir a nota ${invoice.invoiceNumber}`)) {
      excluiInvoice(invoice.id);
    }
    return;
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchInvoice(currentPage - 1, itensPerPage, sortNovo, debouncedSearchValue);
    console.log(sortNovo);
  }

  const columns = [
    { displayName: "Nome do emitente", columnName: "EMITTER_NAME" },
    { displayName: "Nome do destinatário", columnName: "RECEIVER_NAME" },
    { displayName: "Tipo", columnName: "INVOICE_TYPE" },
    { displayName: "Série", columnName: "INVOICE_SERIE" },
    { displayName: "Número", columnName: "INVOICE_NUMBER" },
    { displayName: "Data", columnName: "INVOICE_DATE" },
    { displayName: "Valor", columnName: "INVOICE_VALUE" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Notas Fiscais</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleOpenImport}
                  >
                    Importar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {invoice.map((row, index) => (
                    <CustomStripedTableRow row={row.id} index={index}>
                      <CustomTableCell>{row.emitterName}</CustomTableCell>
                      <CustomTableCell>{row.receiverName}</CustomTableCell>
                      <CustomTableCell>{row.invoiceType}</CustomTableCell>
                      <CustomTableCell>{row.invoiceSerie}</CustomTableCell>
                      <CustomTableCell>{row.invoiceNumber}</CustomTableCell>
                      <CustomTableCell>{formataDateLocal(row.invoiceDate)}</CustomTableCell>
                      <CustomTableCell>{`R$ ${Number(row.invoiceValue).toLocaleString(
                        "pt-BR",
                      )}`}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEditar} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              totalRecords={totalRecords}
              itensPerPage={itensPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Invoice;
