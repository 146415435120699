import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import debounce from "lodash/debounce";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../core/context/GlobalContext";
import invoiceService from "../../../core/services/admin/InvoiceService";
import { ICreClient } from "../../../core/types/Cre/Client/ICreClient";
import { IFisInvoice } from "../../../core/types/Fis/Invoice/IFisInvoice";
import { IPaginatedList } from "../../../core/types/Generic/IPaginatedList";
import { formataDateLocal } from "../../../core/utils/date-utils";
import { sendPost } from "../../../core/utils/httpUtils";
import CustomStripedTableRow from "../Crud/CustomStripedTableRow";
import CustomTable from "../Crud/CustomTable";
import CustomTableBody from "../Crud/CustomTableBody";
import CustomTableContainer from "../Crud/CustomTableContainer";
import CustomModal from "../CustomModal";
import CustomTableCell from "../CustomTableCell";
import NumericFormatCustom from "../NumericFormatCustom";
import Paginacao from "../Paginacao";
import TableHeader from "../TableHeader";
import Titulo from "../Titulo";
import { IPeople } from "../../../core/types/Adm/People/IPeople";

interface NotaFiscalProps {
  selectedInvoices?: IFisInvoice[];
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>, invoice: IFisInvoice) => void;
  refreshFunction?: () => void;
}

export default function NotaFiscalEntradaModal(props: NotaFiscalProps) {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [invoices, setInvoice] = useState<IFisInvoice[]>([]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [sort, setSort] = useState<string>("i.INVOICE_DATE,desc");

  const initialDateDefault = startOfMonth(subMonths(new Date(), 1));

  const finalDateDefault = endOfMonth(addMonths(new Date(), 1));

  const [initialInvoiceDate, setInitialInvoiceDate] = useState<Date>(initialDateDefault);

  const [finalInvoiceDate, setFinalInvoiceDate] = useState<Date>(finalDateDefault);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const [clientsList, setClientsList] = useState<ICreClient[]>([] as ICreClient[]);

  const [debouncedInvoiceSerie, setDebouncedInvoiceSerie] = useState("");

  const [debouncedInvoiceNumber, setDebouncedInvoiceNumber] = useState("");

  const [invoiceSerie, setInvoiceSerie] = useState<string>("");

  const [invoiceNumber, setInvoiceNumber] = useState<string>("");

  const [invoiceType, setInvoiceType] = useState<string>("E");

  const [selectedClient, setSelectedClient] = useState<number>(0);

  const [selectedEmitter, setSelectedEmitter] = useState<number>(0);

  const [selectedReceiver, setSelectedReceiver] = useState<number>(0);

  const [selectedPeople, setSelectedPeople] = useState<number>(0);

  const [emitterList, setEmitterList] = useState<IPeople[]>([] as IPeople[]);

  const [receiverList, setReceiverList] = useState<IPeople[]>([] as IPeople[]);

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchInvoice(
      page - 1,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      selectedEmitter,
      selectedReceiver,
      selectedPeople,
      debouncedInvoiceSerie,
      debouncedInvoiceNumber,
      invoiceType,
    );
  };

  const handleItemsPerPageChange = (itens: number) => {
    searchInvoice(
      0,
      itens,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      selectedEmitter,
      selectedReceiver,
      selectedPeople,
      debouncedInvoiceSerie,
      debouncedInvoiceNumber,
      invoiceType,
    );
  };

  async function searchInvoice(
    page: number,
    itensPerPage: number,
    sort?: string,
    initialInvoiceDate?: Date,
    finalInvoiceDate?: Date,
    emitterId?: number,
    receiverId?: number,
    peopleId?: number,
    invoiceSerie?: string,
    invoiceNumber?: string,
    invoiceType?: string,
  ) {
    setItensPerPage(itensPerPage);
    const response = await invoiceService.searchFilteredInvoice(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      emitterId,
      receiverId,
      peopleId,
      invoiceSerie,
      invoiceNumber,
      invoiceType,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setInvoice(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setInvoice([]);
      addMessages(["Não houve resposta do serviço de notas"]);
    }
  }

  function updateInitialDate(newValue: Date) {
    setInitialInvoiceDate(newValue);
  }
  useEffect(() => {
    searchClients("");
    searchInvoice(
      0,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      selectedEmitter,
      selectedReceiver,
      selectedPeople,
      debouncedInvoiceSerie,
      debouncedInvoiceNumber,
      invoiceType,
    );
  }, [itensPerPage, sort]);

  let jaFezInvoiceSerie: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedInvoiceSerie = useCallback(
    debounce((value: string) => {
      setDebouncedInvoiceSerie(value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFezInvoiceSerie) {
        console.log("initialInvoice", initialInvoiceDate);
        console.log("finalInvoice", finalInvoiceDate);
        searchInvoice(
          currentPage - 1,
          itensPerPage,
          sort,
          initialInvoiceDate,
          finalInvoiceDate,
          selectedEmitter,
          selectedReceiver,
          selectedPeople,
          value,
          debouncedInvoiceNumber,
          invoiceType,
        );
      }
      jaFezInvoiceSerie = true;

      return;
    }, 1000),
    [
      currentPage,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      selectedClient,
      debouncedInvoiceNumber,
      invoiceType,
    ],
  ); // 1000 ms de delay

  let jaFezInvoiceNumber: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedInvoiceNumber = useCallback(
    debounce((value: string) => {
      setDebouncedInvoiceNumber(value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFezInvoiceNumber) {
        searchInvoice(
          currentPage - 1,
          itensPerPage,
          sort,
          initialInvoiceDate,
          finalInvoiceDate,
          selectedEmitter,
          selectedReceiver,
          selectedPeople,
          debouncedInvoiceSerie,
          value,
          invoiceType,
        );
      }
      jaFezInvoiceNumber = true;

      return;
    }, 1000),
    [
      currentPage,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      selectedClient,
      debouncedInvoiceSerie,
      invoiceType,
    ],
  ); // 1000 ms de delay

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedInvoiceSerie(invoiceSerie);
    return updateDebouncedInvoiceSerie.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [invoiceSerie, updateDebouncedInvoiceSerie]);

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedInvoiceNumber(invoiceNumber);
    return updateDebouncedInvoiceNumber.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [invoiceNumber, updateDebouncedInvoiceNumber]);

  async function searchClients(clientName: string) {
    let url = `v1/client/list?size=10&page=0&sort=NAME,asc`;
    const dados = { clientName: clientName };
    try {
      const response = await sendPost<IPaginatedList<ICreClient>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setClientsList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchEmitter(peopleName: string) {
    let url = `v1/admin/people/list?size=10&page=0&sort=NAME,asc`;
    const dados = { peopleName: peopleName };
    try {
      const response = await sendPost<IPaginatedList<IPeople>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setEmitterList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchReceiver(peopleName: string) {
    let url = `v1/admin/people/list?size=10&page=0&sort=NAME,asc`;
    const dados = { peopleName: peopleName };
    try {
      const response = await sendPost<IPaginatedList<IPeople>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setReceiverList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  function handleChangeEmitter(
    event: React.SyntheticEvent<Element, Event>,
    value: IPeople | null,
  ): void {
    const emitterId = value ? value.id : 0;
    setSelectedEmitter(emitterId);
    searchInvoice(
      0,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      emitterId,
      selectedReceiver,
      selectedPeople,
      debouncedInvoiceSerie,
      debouncedInvoiceNumber,
      invoiceType,
    );
  }

  function handleChangeReceiver(
    event: React.SyntheticEvent<Element, Event>,
    value: IPeople | null,
  ): void {
    const receiverId = value ? value.id : 0;
    setSelectedReceiver(receiverId);
    searchInvoice(
      0,
      itensPerPage,
      sort,
      initialInvoiceDate,
      finalInvoiceDate,
      selectedEmitter,
      receiverId,
      selectedPeople,
      debouncedInvoiceSerie,
      debouncedInvoiceNumber,
      invoiceType,
    );
  }

  const columns = [
    { displayName: "", columnName: null },
    { displayName: "Nome do emitente", columnName: null },
    { displayName: "Nome do destinatário", columnName: null },
    { displayName: "Série", columnName: null },
    { displayName: "Numero", columnName: null },
    { displayName: "Data", columnName: null },
    { displayName: "Valor", columnName: null },
  ];

  return (
    <Grid item xs={12}>
      <Button onClick={handleOpen} color="primary" variant="contained">
        Nota Fiscal
      </Button>
      <CustomModal open={open} handleClose={handleClose}>
        <CustomTableContainer sx={{ width: "70%", height: "70%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <Titulo>Nota Fiscal</Titulo>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "130px 130px 130px 130px 130px",
                columnGap: "10px",
                alignItems: "center",
                padding: "10px",
                width: "70%",
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={emitterList}
                getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                sx={{ width: 130 }}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Emitente" name="emitterId" />
                )}
                onInputChange={(event, newInputValue) => {
                  searchEmitter(newInputValue);
                }}
                onChange={handleChangeEmitter}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={receiverList}
                getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                sx={{ width: 130 }}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Destinário" name="receiverId" />
                )}
                onInputChange={(event, newInputValue) => {
                  searchReceiver(newInputValue);
                }}
                onChange={handleChangeReceiver}
              />
              <TextField
                label="Serie"
                variant="outlined"
                value={invoiceSerie}
                onChange={(e) => setInvoiceSerie(e.target.value)}
                placeholder="Digite para buscar..."
                sx={{ width: 130 }}
                size="small"
              />
              <NumericFormatCustom
                label="Numero"
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                prefix=""
                decimalScale={0}
                thousandSeparator=""
                size="small"
                sx={{ width: 130 }}
                variant="outlined"
              />
              <DatePicker
                label="Data inicial"
                value={initialInvoiceDate}
                onChange={(newValue: Date | null) => {
                  updateInitialDate(newValue != null ? newValue : initialInvoiceDate);
                  console.log("initialInvoice 2", newValue != null ? newValue : initialInvoiceDate);
                  searchInvoice(
                    0,
                    itensPerPage,
                    sort,
                    newValue != null ? newValue : initialInvoiceDate,
                    finalInvoiceDate,
                    selectedEmitter,
                    selectedReceiver,
                    selectedPeople,
                    debouncedInvoiceSerie,
                    debouncedInvoiceNumber,
                    invoiceType,
                  );
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    {...params}
                    sx={{ width: 135, height: 40 }}
                    InputLabelProps={{
                      variant: "outlined",
                      style: { fontSize: 13 },
                    }}
                  />
                )}
              />
              <DatePicker
                label="Data final"
                value={finalInvoiceDate}
                onChange={(newValue: Date | null) => {
                  setFinalInvoiceDate(newValue != null ? newValue : finalInvoiceDate);
                  searchInvoice(
                    0,
                    itensPerPage,
                    sort,
                    initialInvoiceDate,
                    newValue != null ? newValue : finalInvoiceDate,
                    selectedEmitter,
                    selectedReceiver,
                    selectedPeople,
                    debouncedInvoiceSerie,
                    debouncedInvoiceNumber,
                    invoiceType,
                  );
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    {...params}
                    sx={{ width: 135, height: 40 }}
                    InputLabelProps={{
                      variant: "outlined",
                      style: { fontSize: 13 },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ margin: "0 12px 0 12px " }}>
            <CustomTable>
              <TableHeader
                initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                initialSortedField={sort.split(",")[0]}
                columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
              />
              <CustomTableBody>
                {invoices &&
                  invoices.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index} key={row.id}>
                      <CustomTableCell>
                        <Checkbox
                          size="small"
                          checked={props.selectedInvoices?.some(
                            (invoice1: { id: number }) => invoice1.id === row.id,
                          )}
                          onChange={(event) => props.handleChange && props.handleChange(event, row)}
                          name="invoices[]"
                          value={row.id}
                          sx={{
                            padding: 0, // Remove o padding extra
                          }}
                        />
                      </CustomTableCell>
                      <CustomTableCell>{row.emitterName}</CustomTableCell>
                      <CustomTableCell>{row.receiverName}</CustomTableCell>
                      <CustomTableCell>{row.invoiceSerie}</CustomTableCell>
                      <CustomTableCell>{row.invoiceNumber}</CustomTableCell>
                      <CustomTableCell>{formataDateLocal(row.invoiceDate)}</CustomTableCell>
                      <CustomTableCell>{`R$ ${Number(row.invoiceValue).toLocaleString(
                        "pt-BR",
                      )}`}</CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
              </CustomTableBody>
            </CustomTable>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              totalRecords={totalRecords}
              itensPerPage={itensPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              sx={{ height: "50%" }}
            >
              Confirmar
            </Button>
          </Box>
        </CustomTableContainer>
      </CustomModal>
    </Grid>
  );
}
