import { ReactNode, createContext, useState } from "react";
import { IAccounts } from "../types/Generic/IAccounts";
import { IConfigWpp } from "../types/Wpp/Config/IConfigWpp";
import { IRoles } from "../types/Adm/Role/IRoles";
import { sendGet } from "../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { IModule } from "../types/Adm/Module/IModule";
import { IConfigs } from "../types/Config/IConfigs";
import configService from "../services/admin/ConfigService";

type GlobalContextType = {
  messages: string[];
  openMessage: boolean;
  loading: boolean;
  configs: IConfigs;
  selectedAccount: string;
  accounts: IAccounts[];
  roles: IRoles[];
  modules: IModule[];
  changeAccounts: (accs: IAccounts[]) => void;
  changeRoles: (roles: IRoles[]) => void;
  changeModules: (modules: IModule[]) => void;
  changeSelectedAccount: (account: string) => void;
  loadConfigs: () => void;
  setaLoading: (loading: boolean) => void;
  closeMessage: () => void;
  addMessage: (message: string) => void;
  addMessages: (messages: string[]) => void;
};

const valorInicialConfigs: IConfigs = {
  account: [],
  user: [],
};

export const GlobalContext = createContext<GlobalContextType>({
  messages: [],
  openMessage: false,
  loading: false,
  configs: valorInicialConfigs,
  selectedAccount: "",
  accounts: [],
  roles: [],
  modules: [],
  changeAccounts: () => {},
  changeRoles: () => {},
  changeModules: () => {},
  changeSelectedAccount: () => {},
  loadConfigs: () => {},
  setaLoading: () => {},
  closeMessage: () => {},
  addMessage: () => {},
  addMessages: () => {},
});

type GlobalProviderProps = {
  children: ReactNode;
};

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<string[]>([]);

  const [openMessage, setOpenMessage] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [configs, setConfigs] = useState<IConfigs>(valorInicialConfigs);

  const [selectedAccount, setSelectedAccount] = useState<string>("");

  const [accounts, setAccounts] = useState<IAccounts[]>([]);
  const [roles, setRoles] = useState<IRoles[]>([]);
  const [modules, setModules] = useState<IModule[]>([]);

  let navigate = useNavigate();

  const setaLoading = (loading: boolean) => {
    setLoading(loading);
  };

  async function loadConfigs() {
    const response = await configService.searchConfigs(
      addMessages,
      setaLoading,
      navigate,
    );
    if (response) {
      setConfigs(response);
    } else {
      setConfigs(valorInicialConfigs);
      addMessages(["Não houve resposta do serviço de configurações"]);
    }
  }

  const changeSelectedAccount = (account: string) => {
    //console.log("currentAccount mudou para " + account);
    sessionStorage.setItem("currentAccount", account);
    setSelectedAccount(account);
    //console.log(accounts);
    //console.log(account);
    setModules(accounts.find((acc) => acc.accountId.toString() === account)?.modules || []);
    loadConfigs();
  };

  const changeAccounts = (accounts: IAccounts[]) => {
    setAccounts(accounts);
  };
  const changeRoles = (roles: IRoles[]) => {
    setRoles(roles);
  };
  const changeModules = (modules: IModule[]) => {
    setModules(modules);
  };

  const addMessage = (message: string) => {
    //console.log("Contexto")
    //console.log(message)
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const addMessages = (messages: string[]) => {
    setMessages([]);
    for (let message of messages) {
      addMessage(message);
    }
    setOpenMessage(true);
  };

  return (
    <GlobalContext.Provider
      value={{
        messages,
        openMessage,
        loading,
        configs,
        selectedAccount,
        accounts,
        roles,
        modules,
        setaLoading,
        closeMessage,
        addMessage,
        addMessages,
        loadConfigs,
        changeSelectedAccount,
        changeAccounts,
        changeRoles,
        changeModules,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
