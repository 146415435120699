import React, { useCallback, useContext, useEffect, useState } from "react";

import "./Config.css";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Stack,
  Tab,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled
} from "@mui/material";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import CheckedActionButton from "../../../../../components/elements/CheckedActionButto";
import CustomStripedTableRow from "../../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTable from "../../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../../components/elements/Crud/CustomTableBody";
import CustomTableContainer from "../../../../../components/elements/Crud/CustomTableContainer";
import CustomTableCell from "../../../../../components/elements/CustomTableCell";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import configService from "../../../../services/admin/ConfigService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigCap = () => {
  const { configs, selectedAccount, loadConfigs, addMessage, addMessages } = useContext(GlobalContext);

  let navigate = useNavigate();
  
  const[userCapDueTodayNotifEmailBool, setUserCapDueTodayNotifEmailBool] = useState(false);
  const[userCapDueTodayNotifWppBool, setUserCapDueTodayNotifWppBool] = useState(false);
  const[userCapDueTodayNotifPushBool, setUserCapDueTodayNotifPushBool] = useState(false);
  const[userCapDueTodayNotifWebBool, setUserCapDueTodayNotifWebBool] = useState(false);
  const[userCapDueTomorrowNotifEmailBool, setUserCapDueTomorrowNotifEmailBool] = useState(false);
  const[userCapDueTomorrowNotifWppBool, setUserCapDueTomorrowNotifWppBool] = useState(false);
  const[userCapDueTomorrowNotifPushBool, setUserCapDueTomorrowNotifPushBool] = useState(false);
  const[userCapDueTomorrowNotifWebBool, setUserCapDueTomorrowNotifWebBool] = useState(false);
  const[userCapOverdueNotifEmailBool, setUserCapOverdueNotifEmailBool] = useState(false);
  const[userCapOverdueNotifWppBool, setUserCapOverdueNotifWppBool] = useState(false);
  const[userCapOverdueNotifPushBool, setUserCapOverdueNotifPushBool] = useState(false);
  const[userCapOverdueNotifWebBool, setUserCapOverdueNotifWebBool] = useState(false);

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const Textarea = styled("textarea")(
    ({ theme }) => `
    width: 700px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    height: 120px;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  const { setaLoading } = useContext(GlobalContext);

  useEffect(() => {
    const loadConfigsCre = async () => {
      await loadConfigs()

      setUserCapDueTodayNotifEmailBool(configService.getUserCapDueTodayNotifEmailBoolValue(configs) ?? false);
      setUserCapDueTodayNotifWppBool(configService.getUserCapDueTodayNotifWppBoolValue(configs) ?? false);
      setUserCapDueTodayNotifPushBool(configService.getUserCapDueTodayNotifPushBoolValue(configs) ?? false);
      setUserCapDueTodayNotifWebBool(configService.getUserCapDueTodayNotifWebBoolValue(configs) ?? false);
      setUserCapDueTomorrowNotifEmailBool(configService.getUserCapDueTomorrowNotifEmailBoolValue(configs) ?? false);
      setUserCapDueTomorrowNotifWppBool(configService.getUserCapDueTomorrowNotifWppBoolValue(configs) ?? false);
      setUserCapDueTomorrowNotifPushBool(configService.getUserCapDueTomorrowNotifPushBoolValue(configs) ?? false);
      setUserCapDueTomorrowNotifWebBool(configService.getUserCapDueTomorrowNotifWebBoolValue(configs) ?? false);
      setUserCapOverdueNotifEmailBool(configService.getUserCapOverdueNotifEmailBoolValue(configs) ?? false);
      setUserCapOverdueNotifWppBool(configService.getUserCapOverdueNotifWppBoolValue(configs) ?? false);
      setUserCapOverdueNotifPushBool(configService.getUserCapOverdueNotifPushBoolValue(configs) ?? false);
      setUserCapOverdueNotifWebBool(configService.getUserCapOverdueNotifWebBoolValue(configs) ?? false);
      
      setValue(1);
    };
    loadConfigsCre();
    
  }, []);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function handleClickChangeActive(row: string): Promise<void> {
      switch (row) {
        case "CAP_DUE_TODAY_NOTIF_EMAIL_BOOL":
          await configService.updateUserCapDueTodayNotifEmailBool(addMessages, setaLoading, navigate, !userCapDueTodayNotifEmailBool);
          setUserCapDueTodayNotifEmailBool(!userCapDueTodayNotifEmailBool);
          break;
        case "CAP_DUE_TODAY_NOTIF_WPP_BOOL":
          await configService.updateUserCapDueTodayNotifWppBool(addMessages, setaLoading, navigate, !userCapDueTodayNotifWppBool);
          setUserCapDueTodayNotifWppBool(!userCapDueTodayNotifWppBool);
          break;
        case "CAP_DUE_TODAY_NOTIF_PUSH_BOOL":
          await configService.updateUserCapDueTodayNotifPushBool(addMessages, setaLoading, navigate, !userCapDueTodayNotifPushBool);
          setUserCapDueTodayNotifPushBool(!userCapDueTodayNotifPushBool);
          break;
        case "CAP_DUE_TODAY_NOTIF_WEB_BOOL":
          await configService.updateUserCapDueTodayNotifWebBool(addMessages, setaLoading, navigate, !userCapDueTodayNotifWebBool);
          setUserCapDueTodayNotifWebBool(!userCapDueTodayNotifWebBool);
          break;
        case "CAP_DUE_TOMORROW_NOTIF_EMAIL_BOOL":
          await configService.updateUserCapDueTomorrowNotifEmailBool(addMessages, setaLoading, navigate, !userCapDueTomorrowNotifEmailBool);
          setUserCapDueTomorrowNotifEmailBool(!userCapDueTomorrowNotifEmailBool);
          break;
        case "CAP_DUE_TOMORROW_NOTIF_WPP_BOOL":
          await configService.updateUserCapDueTomorrowNotifWppBool(addMessages, setaLoading, navigate, !userCapDueTomorrowNotifWppBool);
          setUserCapDueTomorrowNotifWppBool(!userCapDueTomorrowNotifWppBool);
          break;
        case "CAP_DUE_TOMORROW_NOTIF_PUSH_BOOL":
          await configService.updateUserCapDueTomorrowNotifPushBool(addMessages, setaLoading, navigate, !userCapDueTomorrowNotifPushBool);
          setUserCapDueTomorrowNotifPushBool(!userCapDueTomorrowNotifPushBool);
          break;
        case "CAP_DUE_TOMORROW_NOTIF_WEB_BOOL":
          await configService.updateUserCapDueTomorrowNotifWebBool(addMessages, setaLoading, navigate, !userCapDueTomorrowNotifWebBool);
          setUserCapDueTomorrowNotifWebBool(!userCapDueTomorrowNotifWebBool);
          break;
        case "CAP_OVERDUE_NOTIF_EMAIL_BOOL":
          await configService.updateUserCapOverdueNotifEmailBool(addMessages, setaLoading, navigate, !userCapOverdueNotifEmailBool);
          setUserCapOverdueNotifEmailBool(!userCapOverdueNotifEmailBool);
          break;
        case "CAP_OVERDUE_NOTIF_WPP_BOOL":
          await configService.updateUserCapOverdueNotifWppBool(addMessages, setaLoading, navigate, !userCapOverdueNotifWppBool);
          setUserCapOverdueNotifWppBool(!userCapOverdueNotifWppBool);
          break;
        case "CAP_OVERDUE_NOTIF_PUSH_BOOL":
          await configService.updateUserCapOverdueNotifPushBool(addMessages, setaLoading, navigate, !userCapOverdueNotifPushBool);
          setUserCapOverdueNotifPushBool(!userCapOverdueNotifPushBool);
          break;
        case "CAP_OVERDUE_NOTIF_WEB_BOOL":
          await configService.updateUserCapOverdueNotifWebBool(addMessages, setaLoading, navigate, !userCapOverdueNotifWebBool);
          setUserCapOverdueNotifWebBool(!userCapOverdueNotifWebBool);
          break;
        default:
          break;
      }
  }
                                       
  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack spacing={1}>
                <Titulo>Configurações Contas à Pagar</Titulo>
              </Stack>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="Configurações">
                <Tab label="Conta" {...a11yProps(0)} disabled />
                <Tab label="Usuário" {...a11yProps(1)} />
              </Tabs>
            </Box>
              <CustomTabPanel value={value} index={0}>
                <Card sx={{ minWidth: 365, maxWidth: 745 }}>
                  <CardContent sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Conta:</b> {configs.account[0]?.accountName}
                    </Typography>
                    
                  </CardContent>
                </Card>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Card sx={{ minWidth: 660, maxWidth: 800 }}>
                  <CardContent sx={{ alignContent: "initial" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Usuário:</b> {configs.user[0]?.userName}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <CustomTableContainer>
                          <CustomTable>
                            <TableHead>
                              <TableRow>
                                <CustomTableCell
                                 style={{
                                  backgroundColor: "#eee",
                                  padding: "0.3rem 0rem 0.3rem 0.1rem",
                                }}
                                >
                                  Notificações
                                </CustomTableCell>
                                <CustomTableCell
                                  style={{
                                    backgroundColor: "#eee",
                                    padding: "0.3rem 0rem 0.3rem 0.1rem",
                                    width: "60px"
                                  }}
                                  align="center"
                                >
                                  Email
                                </CustomTableCell>
                                <CustomTableCell
                                  style={{
                                    backgroundColor: "#eee",
                                    padding: "0.3rem 0rem 0.3rem 0.1rem",
                                    width: "60px"
                                  }}
                                  align="center"
                                >
                                  WhatsApp
                                </CustomTableCell>
                                <CustomTableCell
                                  style={{
                                    backgroundColor: "#eee",
                                    padding: "0.3rem 0rem 0.3rem 0.1rem",
                                    width: "60px"
                                  }}
                                  align="center"
                                >
                                  Push
                                </CustomTableCell>
                                <CustomTableCell
                                  style={{
                                    backgroundColor: "#eee",
                                    padding: "0.3rem 0rem 0.3rem 0.1rem",
                                    width: "60px"
                                  }}
                                  align="center"
                                >
                                  Web
                                </CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <CustomTableBody>
                              <CustomStripedTableRow row={{"id":"CAP_DUE_TODAY_NOTIF_EMAIL_BOOL"}} index={0}>
                                <CustomTableCell>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                <b>Habilita notificações de contas à pagar vencendo hoje para o usuário do sistema</b>
                                </Typography>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TODAY_NOTIF_EMAIL_BOOL" 
                                    isChecked={userCapDueTodayNotifEmailBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell  align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TODAY_NOTIF_WPP_BOOL" 
                                    isChecked={userCapDueTodayNotifWppBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell  align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TODAY_NOTIF_PUSH_BOOL" 
                                    isChecked={userCapDueTodayNotifPushBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell  align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TODAY_NOTIF_WEB_BOOL" 
                                    isChecked={userCapDueTodayNotifWebBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                              </CustomStripedTableRow>
                              <CustomStripedTableRow row={{"id":"CAP_DUE_TOMORROW_NOTIF_BOOL"}} index={1}>
                                <CustomTableCell>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                <b>Habilita notificações de contas à pagar vencendo amanhã para o usuário do sistema</b>
                                </Typography>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TOMORROW_NOTIF_EMAIL_BOOL" 
                                    isChecked={userCapDueTomorrowNotifEmailBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TOMORROW_NOTIF_WPP_BOOL" 
                                    isChecked={userCapDueTomorrowNotifWppBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TOMORROW_NOTIF_PUSH_BOOL" 
                                    isChecked={userCapDueTomorrowNotifPushBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_DUE_TOMORROW_NOTIF_WEB_BOOL" 
                                    isChecked={userCapDueTomorrowNotifWebBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                              </CustomStripedTableRow>
                              <CustomStripedTableRow row={{"id":"CAP_OVERDUE_NOTIF_BOOL"}} index={2}>
                                <CustomTableCell>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                <b>Habilita notificações de contas à pagar vencidas para o usuário do sistema</b>
                                </Typography>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_OVERDUE_NOTIF_EMAIL_BOOL" 
                                    isChecked={userCapOverdueNotifEmailBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_OVERDUE_NOTIF_WPP_BOOL" 
                                    isChecked={userCapOverdueNotifWppBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_OVERDUE_NOTIF_PUSH_BOOL" 
                                    isChecked={userCapOverdueNotifPushBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                                <CustomTableCell align="center">
                                  <CheckedActionButton 
                                    row="CAP_OVERDUE_NOTIF_WEB_BOOL" 
                                    isChecked={userCapOverdueNotifWebBool} 
                                    handleClickChangeChecked={handleClickChangeActive}/>
                                </CustomTableCell>
                              </CustomStripedTableRow>
                              
                          </CustomTableBody>
                        </CustomTable>
                      </CustomTableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </CustomTabPanel>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default ConfigCap;
