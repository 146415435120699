import { IconButton, Tooltip } from "@mui/material";
import { ReactComponent as AsaasIconImage } from "../../../assets/img/asaas.svg";

export default function AsaasIcon() {
  return (
           <AsaasIconImage
              height={20}
              width={20}
              style={{
                width: "20px",
                height: "20px",
                fontSize: "20px",
                marginRight: "8px",
                padding: "0",
              }}
            />
  );
}
