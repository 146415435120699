import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ICapBill } from "../../../../types/Cap/Bill/ICapBill";
import { ICapBillNewData } from "../../../../types/Cap/Bill/ICapBillNewData";
import { ICreClient } from "../../../../types/Cre/Client/ICreClient";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";
import { sendPost } from "../../../../utils/httpUtils";
import "./NewBill.css";
import { ICapCategory } from "../../../../types/Cap/Category/ICapCategory";
import { ICapSuplier } from "../../../../types/Cap/Suplier/ICapSuplier";
import { ICapSuplierNewData } from "../../../../types/Cap/Suplier/ICapSuplierNewData";
import { ISimpleList } from "../../../../types/Generic/ISimpleList";
import billsService from "../../../../services/admin/BillsService";
import NumericFormatCustom from "../../../../../components/elements/NumericFormatCustom";
import { IBankAccount } from "../../../../types/Ceb/IBankAccount";
import NotaFiscalEntradaModal from "../../../../../components/elements/NotaFiscalEntradaModal";
import TableHeader from "../../../../../components/elements/TableHeader";
import CustomTableCell from "../../../../../components/elements/CustomTableCell";
import { formataDateLocal } from "../../../../utils/date-utils";
import { IInvoice } from "../../../../types/Generic/IInvoice";
import { IFisInvoice } from "../../../../types/Fis/Invoice/IFisInvoice";
import CustomTable from "../../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../../components/elements/Crud/CustomStripedTableRow";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

const NewCapBill = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [totalPages, setTotalPages] = useState<number>(0);

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };

  //Inicia todas as propriedadese do objeto como string vazia
  const [bill, setBill] = useState<ICapBillNewData>({
    suplierId: 0,
    bankAccountId: 0,
    billDescription: "",
    billDate: new Date(),
    billStatus: "Aberto",
    dueDate: new Date(),
    billSerie: "",
    billCode: "",
    payments: [],
    invoices: [],
    categoryId: null,
    vlrPagar: 0,
    vlrDevolucao: 0,
    vlrDesconto: 0,
    saldoDuplicata: 0,
  } as ICapBillNewData);

  const [suplier, setSuplier] = useState<ICapSuplierNewData>({
    peopleName: "",
    peopleCpfCnpj: "",
    peopleFantasyName: "",
    peopleEmail: "",
    whatsapp: "",
    fixedPhone: "",
    addressStreet: "",
    addressNumber: "",
    addressComplement: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressPostalCode: "",
    addressRegion: "",
    municipalInscription: "",
    stateInscription: "",
    observations: "",
    peopleInstagram: "",
    suplierNumber: "",
    peopleAdditionalEmails: "",
  } as ICapSuplierNewData);

  const [suplierList, setSuplierList] = useState<ICapSuplier[]>([] as ICapSuplier[]);

  const [statusList, setStatusList] = useState<ISimpleList[]>([] as ISimpleList[]);

  const [bankAccountsList, setBankAccountsList] = useState<IBankAccount[]>([] as IBankAccount[]);

  const [categoriesList, setCategoriesList] = useState<ICapCategory[]>([] as ICapCategory[]);

  const { selectedAccount, accounts } = useContext(GlobalContext);

  const [temModuloBankAccount, setTemModuloBankAccount] = useState<boolean>(false);

  const [sort, setSort] = useState<string>("p.NAME,asc");

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/cap/bill");
  }

  async function searchSupliers(suplierName: string) {
    let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
    const dados = { suplierName: suplierName };
    try {
      const response = await sendPost<IPaginatedList<ICapSuplier>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setSuplierList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchPaymentsStatusList() {
    const response = await billsService.searchPaymentsStatusList(
      addMessages,
      setaLoading,
      navigate,
    );
    if (response) {
      setStatusList(response);
    } else {
      addMessages(["Não houve resposta do serviço de faturas"]);
    }
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    bill.billSerie = bill.billSerie.trim();
    bill.billCode = bill.billCode.trim();
    if (bill.billSerie === "") {
      addMessages(["Série é obrigatória"]);
      return false;
    }
    if (bill.billCode === "") {
      addMessages(["Codigo é obrigatório"]);
      return false;
    }
    if (bill.billStatus === "") {
      addMessages(["Status é obrigatório"]);
      return false;
    }
    if (bill.suplierId <= 0) {
      addMessages(["Fornecedor é obrigatório"]);
      return false;
    }
    if (bill.dueDate === undefined) {
      addMessages(["Data de Vencimento é obrigatória"]);
      return false;
    }
    if ((bill.vlrPagar && bill.vlrPagar <= 0) || !bill.vlrPagar) {
      addMessages(["Valor a Pagar deve ser maior que zero"]);
      return false;
    }
    if (bill.saldoDuplicata && bill.saldoDuplicata <= 0) {
      addMessages(["Saldo da Duplicata deve ser maior que zero"]);
      return false;
    }
    if (
      bill.saldoDuplicata !== null &&
      bill.vlrPagar !== null &&
      bill.vlrPagar < bill.saldoDuplicata
    ) {
      addMessages(["Valor a Pagar deve ser maior ou igual ao Saldo da Duplicata"]);
      return false;
    }
    if (bill.vlrDevolucao && bill.vlrDevolucao < 0) {
      addMessages(["Valor de Devolução deve ser maior ou igual a zero"]);
      return false;
    }
    if (bill.vlrDesconto && bill.vlrDesconto < 0) {
      addMessages(["Valor de Desconto deve ser maior ou igual a zero"]);
      return false;
    }

    return true;
  }
  async function handleClickSave() {
    if (!validateForm()) {
      return;
    }

    let url = `v1/cap/bill/new`;
    /*Record do backend
        @NotEmpty String name, @NotEmpty String cpfCnpj, String number, String fantasyName, String email, String phone, String mobilePhone, String address, String addressNumber, String complement, String province, String city, String state, String postalCode, String region, String municipalInscription, String stateInscription, String observations, String externalReference, String additionalEmails*/
    const dados = {
      suplierId: bill.suplierId,
      bankAccountId: bill.bankAccountId,
      billDescription: bill.billDescription,
      billDate: bill.billDate,
      billStatus: bill.billStatus,
      dueDate: bill.dueDate,
      invoices: bill.invoices.map((invoice) => invoice.id),
      billSerie: bill.billSerie,
      billCode: bill.billCode,
      categoryId: bill.categoryId,
      vlrPagar: bill.vlrPagar,
      vlrDevolucao: bill.vlrDevolucao,
      vlrDesconto: bill.vlrDesconto,
      saldoDuplicata: bill.saldoDuplicata,
    };
    console.log("dados");
    console.log(dados);
    try {
      const response = await sendPost<ICapBill>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/cap/bill");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchSuplier(suplierName: string) {
    let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
    const dados = { suplierName: suplierName };
    try {
      const response = await sendPost<IPaginatedList<ICapSuplier>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setSuplierList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchCategories(categoryName: string) {
    let url = `v1/cap/category/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: categoryName };
    try {
      const response = await sendPost<IPaginatedList<ICapCategory>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setCategoriesList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchBankAccount(bankAccountName: string) {
    let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: bankAccountName };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setBankAccountsList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setBill((prevBill) => ({ ...prevBill, [name]: valor }));
  };

  useEffect(() => {
    setaLoading(true);
    searchSuplier("");
    searchCategories("");
    searchBankAccount("");
    searchPaymentsStatusList();
    const account = accounts.find((account) => account.accountId.toString() === selectedAccount);
    setTemModuloBankAccount(false);
    if (account) {
      const modulesToCheck = ["Caixas e Bancos"];
      const modules = account.modules;
      console.log(modules);
      if (modules.some((module) => modulesToCheck.includes(module.name))) {
        setTemModuloBankAccount(true);
      } else {
        console.log(`Módulo bankAccount não encontrado com o selectedAccount ${selectedAccount}`);
      }
    } else {
      console.log(`Nenhuma conta encontrada com o selectedAccount ${selectedAccount}`);
    }
    setaLoading(false);
  }, []);

  const handleInvoiceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    invoiceIn: IFisInvoice,
  ) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setBill((prevBill) => ({
        ...prevBill,
        invoices: [...prevBill.invoices, invoiceIn],
      }));
    } else {
      setBill((prevBill) => ({
        ...prevBill,
        invoices: prevBill.invoices.filter((invoice) => invoice.id !== invoiceIn.id),
      }));
    }
  };

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
  }

  const columns = [
    { displayName: "Nome do emitente", columnName: null },
    { displayName: "Nome do destinatário", columnName: null },
    { displayName: "Série", columnName: null },
    { displayName: "Numero", columnName: null },
    { displayName: "Data", columnName: null },
    { displayName: "Valor", columnName: null },
    { displayName: "Ações", columnName: null },
  ];

  const handleClickDeleteInvoice = (invoiceToDelete: IFisInvoice) => {
    if (confirm(`Tem certeza que deseja excluir a nota`)) {
      setBill((prevBill) => {
        return {
          ...prevBill,
          invoices: prevBill.invoices.filter((invoice) => invoice.id !== invoiceToDelete.id),
        };
      });
    }
    return;
  };
  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Conta à Pagar</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-suplier"
                  options={suplierList}
                  getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do fornecedor como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      suplierId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Fornecedor" required />}
                  onInputChange={(event, newInputValue) => {
                    searchSupliers(newInputValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-status"
                  options={statusList}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option.label} // Use o nome da categoria como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={
                    statusList.find((billStatus) => billStatus.label === bill.billStatus) || null
                  }
                  renderInput={(params) => <TextField {...params} label="Status" required />}
                  defaultValue={statusList[0]}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      billStatus: newValue?.label ? newValue.label : "",
                    }));
                  }}
                />
              </Grid>
              {temModuloBankAccount && (
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-bankAccount"
                    options={bankAccountsList}
                    getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                      setBill((prevBill) => ({
                        ...prevBill,
                        bankAccountId: newValue ? newValue.id : 0,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Conta Bancária" />}
                    onInputChange={(event, newInputValue) => {
                      searchBankAccount(newInputValue);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="billDescription"
                  onChange={handleChange}
                  value={bill.billDescription}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Série"
                  name="billSerie"
                  onChange={handleChange}
                  required
                  value={bill.billSerie}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Codigo"
                  name="billCode"
                  onChange={handleChange}
                  required
                  value={bill.billCode}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Data fatura"
                  value={bill.billDate}
                  onChange={(newValue: Date | null) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      billDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="billDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Vencimento fatura"
                  value={bill.dueDate}
                  onChange={(newValue: Date | null) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      dueDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="dueDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-wallet"
                  options={categoriesList}
                  getOptionLabel={(option) =>
                    (option.upperCategoryName ? option.upperCategoryName + " -> " : "") +
                    option.name
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setBill((prevBill) => ({
                      ...prevBill,
                      categoryId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Categoria" required />}
                  onInputChange={(event, newInputValue) => {
                    searchCategories(newInputValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor a Pagar"
                  value={bill.vlrPagar}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({ ...prevBill, vlrPagar: Number(values.target.value) }))
                  }
                  name="vlrPagar"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor Desconto"
                  value={bill.vlrDesconto}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      vlrDesconto: Number(values.target.value),
                    }))
                  }
                  name="vlrDesconto"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor da Devolução"
                  value={bill.vlrDevolucao}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      vlrDevolucao: Number(values.target.value),
                    }))
                  }
                  name="vlrDevolucao"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Saldo da Duplicata"
                  value={bill.saldoDuplicata}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setBill((prevBill) => ({
                      ...prevBill,
                      saldoDuplicata: Number(values.target.value),
                    }))
                  }
                  name="saldoDuplicata"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  sx={{ display: "flex", alignItems: "end" }}
                >
                  <Grid item>
                    <NotaFiscalEntradaModal handleChange={handleInvoiceChange} />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <CustomTable>
                      <TableHeader
                        initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                        initialSortedField={sort.split(",")[0]}
                        columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                        handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                      />
                      <CustomTableBody>
                        {bill.invoices &&
                          bill.invoices.map((row, index) => (
                            <CustomStripedTableRow key={row.id} index={index} row={row}>
                              <CustomTableCell>{row.emitterName}</CustomTableCell>
                              <CustomTableCell>{row.receiverName}</CustomTableCell>
                              <CustomTableCell>{row.invoiceSerie}</CustomTableCell>
                              <CustomTableCell>{row.invoiceNumber}</CustomTableCell>
                              <CustomTableCell>{formataDateLocal(row.invoiceDate)}</CustomTableCell>
                              <CustomTableCell>{`R$ ${Number(row.invoiceValue).toLocaleString(
                                "pt-BR",
                              )}`}</CustomTableCell>
                              <CustomTableCell>
                                <IconButton
                                  sx={{ padding: "0px 4px 0px 0px" }}
                                  onClick={() => handleClickDeleteInvoice(row)}
                                >
                                  <Tooltip title="Excluir">
                                    <SvgIcon fontSize="small">
                                      <TrashIcon aria-label="Excluir" style={iconStyles} />
                                    </SvgIcon>
                                  </Tooltip>
                                </IconButton>
                              </CustomTableCell>
                            </CustomStripedTableRow>
                          ))}
                      </CustomTableBody>
                    </CustomTable>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSave}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default NewCapBill;
