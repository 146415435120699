import { TextField, TextFieldPropsSizeOverrides } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import React from "react";
import { NumericFormat } from "react-number-format";

interface CustomProps {
  name: string;
  label?: string;
  value: number | string | null;
  fullWidth?: boolean;
  style?: any;
  required?: boolean;
  inputProps?: any;
  prefix?: string;
  sufix?: string;
  decimalScale?: number;
  thousandSeparator?: string;
  decimalSeparator?: string;
  sx?: any;
  size?: "small" | "medium";
  variant?: "outlined" | "filled" | "standard";
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function NumericFormatCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={props.thousandSeparator != null ? props.thousandSeparator : "."}
        decimalSeparator={props.decimalSeparator != null ? props.decimalSeparator : ","}
        prefix={props.prefix != null ? props.prefix : "R$ "}
        suffix={props.sufix != null ? props.sufix : ""}
        allowNegative={false}
        decimalScale={props.decimalScale != null ? props.decimalScale : 2}
        fixedDecimalScale={true}
        customInput={TextField}
        value={props.value}
        inputProps={props.inputProps}
        style={props.style}
        required={props.required}
        sx={props.sx}
      />
    );
  },
);

export default NumericFormatCustom;
