import React, { useContext, useEffect, useRef, useState } from "react";

import "./Config.css";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import Titulo from "../../../../../components/elements/Titulo";
import WppQrcode from "../../../../../components/elements/WppQrcode";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ICreConfig } from "../../../../types/Cre/Config/ICreConfig";
import { IResponse } from "../../../../types/Generic/IResponse";
import { ISuccessoMensagensResponse } from "../../../../types/Generic/ISuccessoMensagensResponse";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { IConfigs } from "../../../../types/Config/IConfigs";
import configService from "../../../../services/admin/ConfigService";
import { IAccountConfig } from "../../../../types/Config/IAccountConfig";
import { IUpdateConfig } from "../../../../types/Config/IUpdateConfig";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConfigWpp = () => {
  const { configs, selectedAccount, loadConfigs, addMessage, addMessages } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [accountWppDefaultSendPermitted, setAccountWppDefaultSendPermitted] = useState(false);

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const Textarea = styled("textarea")(
    ({ theme }) => `
    width: 700px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    height: 120px;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  const { setaLoading } = useContext(GlobalContext);

  async function desconectaWppConfig() {
    let url = `v1/config/wpp/disconnect`;
    const dados = {};
    try {
      const response = await sendGet<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      loadConfigs();
      setaLoading(false);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    loadConfigs();
    setAccountWppDefaultSendPermitted(configService.getAccountWppDefaultSendPermittedValue(configs) ?? false);
    setValue(0);
  }, []);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /*
  const response = await bankAccountService.searchFilteredBankAccount(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      searchQuery,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setBankAccounts(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setBankAccounts([]);
      addMessages(["Não houve resposta do serviço de conta bancaria"]);
    }
      */

  const handleChangeAccountWppDefaultSendPermitted = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAccountWppDefaultSendPermitted(checked);
    const response = await configService.updateAccountWppDefaultSendPermitted(addMessages, setaLoading, navigate, checked);
    if (!response) {
      addMessages(["Não houve resposta do serviço de configurações"]);
    }
    loadConfigs();
    
  };

  function handleClickDesconectar(): React.MouseEventHandler<HTMLButtonElement> | undefined {
    desconectaWppConfig();
    loadConfigs();
    return;
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack spacing={1}>
                <Titulo>Configurações WhatsApp</Titulo>
              </Stack>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleTabChange} aria-label="Configurações">
                <Tab label="Conta" {...a11yProps(0)} />
                <Tab label="Usuário" {...a11yProps(1)} disabled/>
              </Tabs>
            </Box>
              <CustomTabPanel value={value} index={0}>
                <Card sx={{ minWidth: 365, maxWidth: 365 }}>
                  <CardContent sx={{ padding: "10px" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Conta:</b> {configs.account[0]?.accountName}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Instância:</b> {configService.getAccountWppInstanceIdValue(configs)}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Status:</b> {configService.getAccountWppInstanceStatusValue(configs)}
                    </Typography>
                    {configService.getAccountWppInstanceStatusValue(configs) === "Conectada" && (
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Telefone Conectado:</b> {configService.getAccountWppConnectedNumberValue(configs)}
                      </Typography>
                    )}
                    {configService.getAccountWppInstanceStatusValue(configs) === "Desconectada" && (
                      <WppQrcode conectado={false} />
                    )}
                  </CardContent>
                  <CardActions sx={{ padding: "4px" }}>
                    {configService.getAccountWppInstanceStatusValue(configs) === "Conectada" && (
                      <Button size="small" onClick={() => handleClickDesconectar()}>
                        <b>Desconectar</b>
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Card sx={{ minWidth: 660, maxWidth: 800 }}>
                  <CardContent sx={{ alignContent: "initial" }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <b>Usuário:</b>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => void 0}
                      disabled={true}
                    >
                      Atualizar
                    </Button>
                  </CardActions>
                </Card>
              </CustomTabPanel>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default ConfigWpp;
