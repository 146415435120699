import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import { IClosureGraphic } from "../../../types/Dash/IClosureGraphic";

import Titulo from "../../../../components/elements/Titulo";

import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import { IWppGraphic } from "../../../types/Dash/IWppGraphic";
import "./Dashboard.css";

import { format } from "date-fns";
import { GlobalContext } from "../../../context/GlobalContext";
import { IGraphicClosure2 } from "../../../types/Dash/IClosureGraphic2";
import { sendPost } from "../../../utils/httpUtils";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { addMessages, setaLoading, selectedAccount } = React.useContext(GlobalContext);

  let navigate = useNavigate();

  const diasGraficoInicial = 90;
  const [dataInicialGrafico, setDataInicialGrafico] = useState<Date>(
    subDays(new Date(), diasGraficoInicial),
  );
  const [dataFinalGrafico, setDataFinalGrafico] = useState<Date>(new Date());

  const formatador = Intl.NumberFormat("pt-br", { style: "currency", currency: "BRL" });

  interface SeriesData {
    data: number[];
    color: string;
    description: string;
  }

  interface XAxisData {
    scaleType: "time" | "linear" | "band" | "point" | "log" | "pow" | "sqrt" | "utc";
    data: string[];
  }

  interface LegendProps {
    series: SeriesData[];
  }

  const Legend: React.FC<LegendProps> = ({ series }) => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      {series.map((s, i) => (
        <div key={i} style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
          <div style={{ width: 10, height: 10, backgroundColor: s.color, marginRight: 5 }}></div>
          <div>{s.description}</div>
        </div>
      ))}
    </div>
  );

  interface ICarteira {
    id: number;
    number: string;
    name: string;
    series: SeriesData[];
    xAxis: XAxisData[];
  }

  interface ICliente {
    id: number;
    name: string;
    series: SeriesData[];
    xAxis: XAxisData[];
  }

  interface IGraficoMensagem {
    series: SeriesData[];
    xAxis: XAxisData[];
  }

  interface IDadosMensagem {
    initialCreateDate: Date | null;
    finalCreateDate: Date | null;
  }

  interface IDadosFechamento {
    initialClosureDate: Date | null;
    finalClosureDate: Date | null;
  }

  const [clientesGraficoFechamento, setClientesGraficoFechamento] = useState<ICliente[]>([]);
  const [clienteGraficoFechamentoSelecionado, setClienteGraficoFechamentoSelecionado] =
    useState<ICliente | null>(null);
  //const [graficoFechamento2Data, setGraficoFechamento2Data] = useState<IGraficoFechamento2[] | null>([]);

  async function consultaGraficoFechamento2(dataInicial: Date, dataFinal: Date) {
    let url = `v1/closure/chart2`;
    const dados: IDadosFechamento = {
      initialClosureDate: dataInicial,
      finalClosureDate: dataFinal,
    };
    try {
      const response = await sendPost<IGraphicClosure2[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      const clientes: ICliente[] = response.reduce((acc: ICliente[], item) => {
        let cliente = acc.find((cliente) => cliente.id === item.clientId);
        if (!cliente) {
          cliente = {
            id: item.clientId,
            name: item.clientName,
            series: [], // Inicializa o array de series
            xAxis: [], // Inicializa o array de xAxis
          };

          const serieReceberVazia: SeriesData = {
            data: [],
            color: "#3498db",
            description: "Vendas",
          };
          const serieSaldoVazia: SeriesData = {
            data: [],
            color: "#a2c633",
            description: "Total à receber",
          };
          const serieDevolucaoVazia: SeriesData = {
            data: [],
            color: "#e74c3c",
            description: "Devoluções",
          };
          const xAxisVazia: XAxisData = { scaleType: "band", data: [] };
          cliente.series.push(serieSaldoVazia, serieReceberVazia, serieDevolucaoVazia);
          cliente.xAxis.push(xAxisVazia);

          acc.push(cliente);
        }
        // Adiciona os dados de saldo à carteira correspondente
        cliente.series[0].data.push(item.saldoTotal);
        cliente.series[1].data.push(item.vlrReceber);
        cliente.series[2].data.push(item.vlrDevolucao);
        cliente.xAxis[0].data.push(item.closureDate);
        // Adicione aqui os dados para xAxis se necessário
        return acc;
      }, []);
      setClientesGraficoFechamento(clientes);
      setClienteGraficoFechamentoSelecionado(clientes[0]);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const [carteirasGraficoFechamento, setCarteirasGraficoFechamento] = useState<ICarteira[]>([]);
  const [carteiraGraficoFechamentoSelecionada, setCarteiraGraficoFechamentoSelecionada] =
    useState<ICarteira | null>(null);
  const [totaisGraficoFechamentoData, setTotaisGraficoFechamentoData] = useState<
    IClosureGraphic[] | null
  >([]);

  async function consultaGraficoFechamento(dataInicial: Date, dataFinal: Date) {
    let url = `v1/closure/chart`;
    const dados: IDadosFechamento = {
      initialClosureDate: dataInicial,
      finalClosureDate: dataFinal,
    };
    try {
      const response = await sendPost<IClosureGraphic[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      const somaPorData = response.reduce(
        (soma, item) => {
          const closureDate = item.closureDate; // ajuste isso para corresponder à sua estrutura de dados
          const vlrReceber = item.vlrReceber; // ajuste isso para corresponder à sua estrutura de dados
          const vlrDevolucao = item.vlrDevolucao; // ajuste isso para corresponder à sua estrutura de dados
          const saldoTotal = item.saldoTotal; // ajuste isso para corresponder à sua estrutura de dados

          if (!soma[closureDate]) {
            soma[closureDate] = {
              walletId: 0,
              walletName: "",
              walletNumber: "",
              vlrReceber: 0,
              vlrDevolucao: 0,
              saldoTotal: 0,
            };
          }

          soma[closureDate].vlrReceber += vlrReceber;
          soma[closureDate].vlrDevolucao += vlrDevolucao;
          soma[closureDate].saldoTotal += saldoTotal;

          return soma;
        },
        {} as {
          [key: string]: {
            walletId: number;
            walletName: string;
            walletNumber: string;
            vlrReceber: number;
            vlrDevolucao: number;
            saldoTotal: number;
          };
        },
      );

      const somarizado: IClosureGraphic[] = Object.entries(somaPorData).map(
        ([
          closureDate,
          { walletId, walletName, walletNumber, vlrReceber, vlrDevolucao, saldoTotal },
        ]) => ({
          closureDate,
          walletId,
          walletName,
          walletNumber,
          vlrReceber,
          vlrDevolucao,
          saldoTotal,
        }),
      );

      setTotaisGraficoFechamentoData(somarizado);

      const carteiras: ICarteira[] = response.reduce((acc: ICarteira[], item) => {
        let carteira = acc.find((carteira) => carteira.id === item.walletId);
        if (!carteira) {
          carteira = {
            id: item.walletId,
            number: item.walletNumber,
            name: item.walletName,
            series: [], // Inicializa o array de series
            xAxis: [], // Inicializa o array de xAxis
          };

          const serieReceberVazia: SeriesData = {
            data: [],
            color: "#3498db",
            description: "Vendas",
          };
          const serieSaldoVazia: SeriesData = {
            data: [],
            color: "#a2c633",
            description: "Total à receber",
          };
          const serieDevolucaoVazia: SeriesData = {
            data: [],
            color: "#e74c3c",
            description: "Devoluções",
          };
          const xAxisVazia: XAxisData = { scaleType: "band", data: [] };
          carteira.series.push(serieSaldoVazia, serieReceberVazia, serieDevolucaoVazia);
          carteira.xAxis.push(xAxisVazia);

          acc.push(carteira);
        }
        // Adiciona os dados de saldo à carteira correspondente
        carteira.series[0].data.push(item.saldoTotal);
        carteira.series[1].data.push(item.vlrReceber);
        carteira.series[2].data.push(item.vlrDevolucao);
        carteira.xAxis[0].data.push(item.closureDate);
        // Adicione aqui os dados para xAxis se necessário
        return acc;
      }, []);
      setCarteirasGraficoFechamento(carteiras);
      setCarteiraGraficoFechamentoSelecionada(carteiras[0]);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const [graficoMensagemSelecionado, setGraficoMensagemSelecionado] =
    useState<IGraficoMensagem | null>(null);
  const [graficoWppData, setGraficoWppData] = useState<IWppGraphic[] | null>([]);
  async function consultaGraficoMensagens(dataInicial: Date, dataFinal: Date) {
    let url = `v1/wpp/chart`;
    const dados: IDadosMensagem = { initialCreateDate: dataInicial, finalCreateDate: dataFinal };
    try {
      const response = await sendPost<IWppGraphic[]>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setGraficoWppData(response);
      const serieSaldoVazia: SeriesData = {
        data: [],
        color: "#a2c633",
        description: "Quantidade de mensagens",
      };
      const xAxisVazia: XAxisData = { scaleType: "band", data: [] };

      serieSaldoVazia.data = response.map((item) => item.qtdChats);
      xAxisVazia.data = response.map((item) => item.createDate);

      if (serieSaldoVazia.data.length === 0) {
        serieSaldoVazia.data.push(0);
        xAxisVazia.data.push(format(dataInicial, "dd/MM/yyyy"));
      }

      const grafico: IGraficoMensagem = {
        series: [serieSaldoVazia],
        xAxis: [xAxisVazia],
      };

      setGraficoMensagemSelecionado(grafico);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  function consultaGraficos(dataInicial: Date, dataFinal: Date) {
    if (dataInicial instanceof Date === false) {
      dataInicial = new Date(dataInicial);
    }
    if (dataFinal instanceof Date === false) {
      dataFinal = new Date(dataFinal);
    }
    // Valida que a data final não pode ser menor que a data inicial
    if (dataFinal.getTime() < dataInicial.getTime()) {
      alert("A data final não pode ser menor que a data inicial");
      return;
    }

    dataInicial.setHours(0, 0, 0, 0);

    // Define a hora final para 23:59:59
    dataFinal.setHours(23, 59, 59, 999);
    consultaGraficoMensagens(dataInicial, dataFinal);
    if (selectedAccount === "2") {
      consultaGraficoFechamento(dataInicial, dataFinal);
      consultaGraficoFechamento2(dataInicial, dataFinal);
    }
  }

  useEffect(() => {
    consultaGraficos(dataInicialGrafico, dataFinalGrafico);
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Stack>
                  <Titulo>Dashboard</Titulo>
                </Stack>
                <Stack>
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <DatePicker
                      label="Data inicial"
                      value={dataInicialGrafico}
                      onChange={(newValue: Date | null) => {
                        setDataInicialGrafico(
                          newValue ? newValue : subDays(new Date(), diasGraficoInicial),
                        );
                        consultaGraficos(
                          newValue ? newValue : subDays(new Date(), diasGraficoInicial),
                          dataFinalGrafico,
                        );
                      }}
                      renderInput={(params: TextFieldProps) => (
                        <TextField {...params} style={{ width: 140 }} />
                      )}
                    />
                    <DatePicker
                      label="Data final"
                      value={dataFinalGrafico}
                      onChange={(newValue: Date | null) => {
                        setDataFinalGrafico(newValue ? newValue : new Date());
                        consultaGraficos(dataInicialGrafico, newValue ? newValue : new Date());
                      }}
                      renderInput={(params: TextFieldProps) => (
                        <TextField {...params} style={{ width: 140 }} />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="column" justifyContent="space-between" spacing={1}>
                <Stack alignItems="center" direction="row" justifyContent="start" spacing={1}>
                  {selectedAccount === "2" && (
                    <Stack
                      alignItems="right"
                      direction="column"
                      border={1}
                      borderColor="#dcdcdc"
                      padding={2}
                      spacing={1}
                      width={450}
                      height={150}
                      overflow={"auto"}
                    >
                      <Stack direction="column">
                        <Typography variant="body2" fontWeight={"bold"}>
                          Totais Fechamentos&nbsp;
                        </Typography>
                        <Stack direction="row">
                          <Typography variant="body2">Data:&nbsp;</Typography>
                          <Typography variant="body2" color={"#a2c633"} fontWeight={"bold"}>
                            Total à receber&nbsp;
                          </Typography>
                          <Typography variant="body2" fontWeight={"bold"}>
                            |&nbsp;
                          </Typography>
                          <Typography variant="body2" color={"#3498db"} fontWeight={"bold"}>
                            Vendas&nbsp;
                          </Typography>
                          <Typography variant="body2" fontWeight={"bold"}>
                            |&nbsp;
                          </Typography>
                          <Typography variant="body2" color={"#e74c3c"} fontWeight={"bold"}>
                            Devoluções
                          </Typography>
                        </Stack>
                        {totaisGraficoFechamentoData?.map((item) => {
                          return (
                            <Stack direction="row">
                              <Typography variant="body2">{item.closureDate}:&nbsp;</Typography>
                              <Typography variant="body2" color={"#a2c633"} fontWeight={"bold"}>
                                {`R$ ${item.saldoTotal.toLocaleString("pt-BR")}`}&nbsp;
                              </Typography>
                              <Typography variant="body2" fontWeight={"bold"}>
                                |&nbsp;
                              </Typography>
                              <Typography variant="body2" color={"#3498db"} fontWeight={"bold"}>
                                {`R$ ${item.vlrReceber.toLocaleString("pt-BR")}`}&nbsp;
                              </Typography>
                              <Typography variant="body2" fontWeight={"bold"}>
                                |&nbsp;
                              </Typography>
                              <Typography
                                variant="body2"
                                color={"#e74c3c"}
                                fontWeight={"bold"}
                              >{`R$ ${item.vlrDevolucao.toLocaleString("pt-BR")}`}</Typography>
                            </Stack>
                          );
                        })}
                        <Stack direction="row">
                          <Typography variant="body2">Total:&nbsp;</Typography>
                          <Typography variant="body2" color={"#a2c633"} fontWeight={"bold"}>
                            {`R$ ${totaisGraficoFechamentoData?.reduce((acc, item) => acc + item.saldoTotal, 0).toLocaleString("pt-BR")}`}
                            &nbsp;
                          </Typography>
                          <Typography variant="body2" fontWeight={"bold"}>
                            |&nbsp;
                          </Typography>
                          <Typography variant="body2" color={"#3498db"} fontWeight={"bold"}>
                            {`R$ ${totaisGraficoFechamentoData?.reduce((acc, item) => acc + item.vlrReceber, 0).toLocaleString("pt-BR")}`}
                            &nbsp;
                          </Typography>
                          <Typography variant="body2" fontWeight={"bold"}>
                            |&nbsp;
                          </Typography>
                          <Typography
                            variant="body2"
                            color={"#e74c3c"}
                            fontWeight={"bold"}
                          >{`R$ ${totaisGraficoFechamentoData?.reduce((acc, item) => acc + item.vlrDevolucao, 0).toLocaleString("pt-BR")}`}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                  <Stack
                    alignItems="right"
                    direction="column"
                    border={1}
                    borderColor="#dcdcdc"
                    padding={2}
                    spacing={1}
                    width={200}
                    height={150}
                    overflow={"auto"}
                  >
                    <Stack direction="column">
                      <Typography variant="body2" fontWeight={"bold"}>
                        Mensagens&nbsp;
                      </Typography>
                      {graficoWppData?.map((item) => {
                        return (
                          <Stack direction="row">
                            <Typography variant="body2">{item.createDate}:&nbsp;</Typography>
                            <Typography variant="body2" color={"#a2c633"} fontWeight={"bold"}>
                              {item.qtdChats}
                            </Typography>
                          </Stack>
                        );
                      })}
                      <Stack direction="row">
                        <Typography variant="body2">Total:&nbsp;</Typography>
                        <Typography variant="body2" color={"#a2c633"} fontWeight={"bold"}>
                          {graficoMensagemSelecionado?.series[0].data.reduce(
                            (acc, item) => acc + item,
                            0,
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>

                  <Stack
                    alignItems="right"
                    direction="column"
                    border={1}
                    borderColor="#dcdcdc"
                    padding={2}
                    spacing={1}
                    width={300}
                    height={150}
                    overflow={"auto"}
                  >
                    <BarChart
                      xAxis={graficoMensagemSelecionado?.xAxis}
                      series={graficoMensagemSelecionado?.series || []}
                      width={300}
                      height={200}
                    />
                    <Legend series={graficoMensagemSelecionado?.series || []} />
                  </Stack>
                </Stack>
                {selectedAccount === "2" && (
                  <>
                    <Stack
                      alignItems="center"
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Stack
                        alignItems="left"
                        direction="column"
                        border={1}
                        borderColor="#dcdcdc"
                        padding={2}
                        spacing={1}
                      >
                        <Typography variant="body2" fontWeight={"bold"}>
                          Fechamentos por carteira
                        </Typography>
                        <Select
                          sx={{ width: 300 }}
                          value={carteiraGraficoFechamentoSelecionada?.id || ""}
                          onChange={(event) => {
                            const carteiraSelecionada = carteirasGraficoFechamento.find(
                              (carteira) => carteira.id === Number(event.target.value),
                            );
                            setCarteiraGraficoFechamentoSelecionada(carteiraSelecionada || null);
                          }}
                        >
                          {carteirasGraficoFechamento.map((carteira, index) => (
                            <MenuItem key={index} value={carteira.id}>
                              {carteira.number + " - " + carteira.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <BarChart
                          xAxis={carteiraGraficoFechamentoSelecionada?.xAxis}
                          series={carteiraGraficoFechamentoSelecionada?.series || []}
                          width={1038}
                          height={300}
                        />
                        <Legend series={carteiraGraficoFechamentoSelecionada?.series || []} />
                      </Stack>
                    </Stack>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Stack
                        alignItems="left"
                        direction="column"
                        border={1}
                        borderColor="#dcdcdc"
                        padding={2}
                        spacing={1}
                      >
                        <Typography variant="body2" fontWeight={"bold"}>
                          Fechamentos por cliente
                        </Typography>
                        <Select
                          sx={{ width: 300 }}
                          value={clienteGraficoFechamentoSelecionado?.id || ""}
                          onChange={(event) => {
                            const clienteSelecionado = clientesGraficoFechamento.find(
                              (cliente) => cliente.id === Number(event.target.value),
                            );
                            setClienteGraficoFechamentoSelecionado(clienteSelecionado || null);
                          }}
                        >
                          {clientesGraficoFechamento.map((cliente, index) => (
                            <MenuItem key={index} value={cliente.id}>
                              {cliente.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <BarChart
                          xAxis={clienteGraficoFechamentoSelecionado?.xAxis}
                          series={clienteGraficoFechamentoSelecionado?.series || []}
                          width={1038}
                          height={300}
                        />
                        <Legend series={clienteGraficoFechamentoSelecionado?.series || []} />
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
