import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import RolesCheckboxGrid from "../../../../../components/elements/grids/RolesCheckboxGrid";
import Titulo from "../../../../../components/elements/Titulo";
import http from "../../../../services/http";
import { IMenu } from "../../../../types/Adm/Menu/IMenu";
import { IMenuNewData } from "../../../../types/Adm/Menu/IMenuNewData";
import { IRole } from "../../../../types/Adm/Role/IRole";
import { IResponse } from "../../../../types/Generic/IResponse";
import "./InclusaoContas.css";
import { IUserNewData } from "../../../../types/Adm/User/IUserNewData";
import AccountsCheckboxGrid from "../../../../../components/elements/grids/AccountsCheckboxGrid";
import { IAccount } from "../../../../types/Adm/Account/IAccount";
import { IAccountNewData } from "../../../../types/Adm/Account/IAccountNewData";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { IPlan } from "../../../../types/Adm/Plan/IPlan";
import ModulesCheckboxGrid from "../../../../../components/elements/grids/ModulesCheckboxGrid";
import PlanosCheckboxGrid from "../../../../../components/elements/grids/PlanosCheckboxGrid";
import { sendPost, sendSimpleGet } from "../../../../utils/httpUtils";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IMaskInput } from "react-imask";
import { ICep } from "../../../../types/Generic/ICep";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CepMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CepMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CnpjMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CnpjMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/000#-00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CpfMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CpfMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const PhoneMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const WhatsAppMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function WhatsAppMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);
const InclusaoContas = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [peopleType, setPeopleType] = useState("F");

  const [conta, setConta] = useState<IAccountNewData>({
    name: "",
    fantasyName: "",
    cpfCnpj: "",
    municipalInscription: "",
    stateInscription: "",
    email: "",
    addressPostalCode: "",
    addressStreet: "",
    addressNumber: "",
    addressComplement: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressRegion: "",
    whatsapp: "",
    fixedPhone: "",
    additionalEmails: "",
    instagram: "",
    active: true,
    bloqued: false,
    modules: [],
    planos: [],
  } as IAccountNewData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/contas");
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateCpf(cpf: string) {
    if (cpf.length !== 11) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }

  function validateCnpj(cnpj: string) {
    if (cnpj === "" || cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return false;
    }

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) {
      return false;
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) {
      return false;
    }

    return true;
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    conta.name = conta.name.trim();
    conta.cpfCnpj = conta.cpfCnpj.trim();
    conta.addressPostalCode = conta.addressPostalCode.trim();
    conta.addressNumber = conta.addressNumber.trim();
    conta.email = conta.email.trim();
    conta.whatsapp = conta.whatsapp.trim();
    conta.fixedPhone = conta.fixedPhone.trim();
    conta.instagram = conta.instagram.trim();
    conta.additionalEmails = conta.additionalEmails.trim();
    if (peopleType === "F") {
      conta.fantasyName = conta.fantasyName.trim();
      conta.municipalInscription = conta.municipalInscription.trim();
      conta.stateInscription = conta.stateInscription.trim();
    } else {
      conta.fantasyName = "";
      conta.municipalInscription = "";
      conta.stateInscription = "";
    }
    //Retirar caracteres não numéricos dos campos de cpf/cnpj, telefone e celular
    conta.cpfCnpj = conta.cpfCnpj.replace(/\D/g, "");
    conta.whatsapp = conta.whatsapp.replace(/\D/g, "");
    conta.fixedPhone = conta.fixedPhone.replace(/\D/g, "");
    conta.addressPostalCode = conta.addressPostalCode.replace(/\D/g, "");

    if (conta.name === "") {
      addMessages(["Nome é obrigatório"]);
      return false;
    }
    if (conta.cpfCnpj === "") {
      addMessages(["CPF/CNPJ é obrigatório"]);
      return false;
    } else {
      if (peopleType === "F") {
        if (!validateCpf(conta.cpfCnpj)) {
          addMessages(["CPF inválido"]);
          return false;
        }
      } else {
        if (!validateCnpj(conta.cpfCnpj)) {
          addMessages(["CNPJ inválido"]);
          return false;
        }
      }
    }
    if (conta.addressPostalCode === "") {
      addMessages(["CEP do endereço é obrigatório"]);
      return false;
    }
    if (conta.addressNumber === "") {
      addMessages(["Número do endereço é obrigatório"]);
      return false;
    }

    if (conta.email !== "") {
      if (!validateEmail(conta.email)) {
        addMessages(["Email inválido"]);
        return false;
      }
    }
    if (conta.whatsapp !== "") {
      if (conta.whatsapp.length > 13 || conta.whatsapp.length < 12) {
        addMessages(["Whatsapp inválido"]);
        return false;
      }
    }
    if (conta.fixedPhone !== "") {
      if (conta.fixedPhone.length > 13 || conta.fixedPhone.length < 12) {
        addMessages(["Telefone inválido"]);
        return false;
      }
    }
    return true;
  }

  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }

    let url = `v1/admin/accounts/new`;
    const dados = conta;
    try {
      const response = await sendPost<IAccount>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/contas");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;

    let valor = type === "checkbox" ? target.checked : target.value;

    setConta((prevConta) => ({ ...prevConta, [name]: valor }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setConta((prevAccount) => ({ ...prevAccount, [name]: valor }));
    if (name === "addressPostalCode" && valor.length === 9) {
      fetchCep(valor);
    }
  };

  async function fetchCep(cep: string) {
    let url = `https://viacep.com.br/ws/${cep}/json/`;
    const dados = {};
    //console.log(dados)
    try {
      const response = await sendSimpleGet<ICep>(url, dados, addMessages, setaLoading, navigate);
      console.log(response);
      setConta((prevConta) => ({
        ...prevConta,
        addressStreet: response.logradouro,
        addressNeighborhood: response.bairro,
        addressCity: response.localidade,
        addressState: response.uf,
      }));
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleModuloChange = (event: React.ChangeEvent<HTMLInputElement>, module: IModule) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setConta((prevConta) => ({ ...prevConta, modules: [...prevConta.modules, module] }));
    } else {
      setConta((prevConta) => ({
        ...prevConta,
        modules: prevConta.modules.filter((module1) => module1.id !== module.id),
      }));
    }
  };

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>, plano: IPlan) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setConta((prevConta) => ({ ...prevConta, planos: [...prevConta.planos, plano] }));
    } else {
      setConta((prevConta) => ({
        ...prevConta,
        planos: prevConta.planos.filter((plane) => plane.id !== plano.id),
      }));
    }
  };

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Conta</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-buttons-group-people-type-label">Tipo Pessoa</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-people-type-label"
                    defaultValue={peopleType}
                    name="peopleType"
                    onChange={(event) => {
                      setPeopleType(event.target.value);
                      if (event.target.value === "F") {
                        setConta((prevAccount) => ({
                          ...prevAccount,
                          fantasyName: "",
                          municipalInscription: "",
                          stateInscription: "",
                        }));
                      }
                    }}
                  >
                    <FormControlLabel value="F" control={<Radio />} label="Física" />
                    <FormControlLabel value="J" control={<Radio />} label="Jurídica" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  value={conta.name}
                  name="name"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nome Fantasia"
                  value={conta.fantasyName}
                  name="fantasyName"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CPF/CNPJ"
                  value={conta.cpfCnpj}
                  name="cpfCnpj"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={
                    peopleType === "F"
                      ? { inputComponent: CpfMaskCustom as any }
                      : { inputComponent: CnpjMaskCustom as any }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Inscrição Municipal"
                  value={conta.municipalInscription}
                  name="municipalInscription"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Inscrição Estadual"
                  value={conta.stateInscription}
                  name="stateInscription"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={conta.email}
                  name="email"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CEP"
                  value={conta.addressPostalCode}
                  name="addressPostalCode"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: CepMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rua"
                  value={conta.addressStreet}
                  name="addressStreet"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero"
                  value={conta.addressNumber}
                  name="addressNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  value={conta.addressComplement}
                  name="addressComplement"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bairro"
                  value={conta.addressNeighborhood}
                  name="addressNeighborhood"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cidade"
                  value={conta.addressCity}
                  name="addressCity"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Estado"
                  value={conta.addressState}
                  name="addressState"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Região"
                  value={conta.addressRegion}
                  name="addressRegion"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="WhastApp"
                  value={conta.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: WhatsAppMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Celular"
                  value={conta.fixedPhone}
                  name="fixedPhone"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: PhoneMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Instagram"
                  value={conta.instagram}
                  name="instagram"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Emails Adicionais"
                  value={conta.additionalEmails}
                  name="additionalEmails"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conta.active || false}
                      onChange={handleCheckBoxChange}
                      name="active"
                      value={conta.active}
                    />
                  }
                  label="Ativa"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conta.bloqued || false}
                      onChange={handleCheckBoxChange}
                      name="bloqued"
                      value={conta.bloqued}
                    />
                  }
                  label="Bloqueada"
                />
              </Grid>
              <ModulesCheckboxGrid
                selectedModules={conta.modules}
                handleChange={handleModuloChange}
              />
              <PlanosCheckboxGrid
                planosSelecionados={conta.planos}
                handleChange={handlePlanChange}
              />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default InclusaoContas;
