import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate, useParams } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import AccountsCheckboxGrid from "../../../../../components/elements/grids/AccountsCheckboxGrid";
import RolesCheckboxGrid from "../../../../../components/elements/grids/RolesCheckboxGrid";
import { GlobalContext } from "../../../../context/GlobalContext";
import http from "../../../../services/http";
import { IAccount } from "../../../../types/Adm/Account/IAccount";
import { IRole } from "../../../../types/Adm/Role/IRole";
import { IResponse } from "../../../../types/Generic/IResponse";
import { IUser } from "../../../../types/Adm/User/IUser";
import { IAccountEditData } from "../../../../types/Adm/Account/IAccountEditData";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import "./EdicaoContas.css";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { IPlan } from "../../../../types/Adm/Plan/IPlan";
import ModulesCheckboxGrid from "../../../../../components/elements/grids/ModulesCheckboxGrid";
import PlanosCheckboxGrid from "../../../../../components/elements/grids/PlanosCheckboxGrid";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const PhoneMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const WhatsAppMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function WhatsAppMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CpfMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CpfMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CnpjMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CnpjMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/000#-00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CepMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CepMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const EdicaoContas = () => {
  const { id } = useParams();

  const contaId = Number(id);

  const [peopleType, setPeopleType] = useState("");

  const [conta, setConta] = useState<IAccountEditData>({
    id: contaId,
    name: "",
    fantasyName: "",
    cpfCnpj: "",
    municipalInscription: "",
    stateInscription: "",
    email: "",
    addressPostalCode: "",
    addressStreet: "",
    addressNumber: "",
    addressComplement: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressRegion: "",
    whatsapp: "",
    fixedPhone: "",
    additionalEmails: "",
    instagram: "",
    active: false,
    bloqued: false,
    modules: [],
    planos: [],
  } as IAccountEditData);

  const { addMessages, setaLoading } = useContext(GlobalContext);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/contas");
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateCpf(cpf: string) {
    if (cpf.length !== 11) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }

  function validateCnpj(cnpj: string) {
    if (cnpj === "" || cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return false;
    }

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) {
      return false;
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) {
      return false;
    }

    return true;
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    //colocar verificação de null ou undefined antes do trim
    if (conta.name !== null && conta.name !== undefined) {
      conta.name = conta.name.trim();
    }
    if (conta.cpfCnpj !== null && conta.cpfCnpj !== undefined) {
      conta.cpfCnpj = conta.cpfCnpj.trim().replace(/\D/g, "");
    }
    if (conta.email !== null && conta.email !== undefined) {
      conta.email = conta.email.trim();
    }
    if (conta.addressPostalCode !== null && conta.addressPostalCode !== undefined) {
      conta.addressPostalCode = conta.addressPostalCode.trim().replace(/\D/g, "");
    }
    if (conta.addressStreet !== null && conta.addressStreet !== undefined) {
      conta.addressStreet = conta.addressStreet.trim();
    }
    if (conta.addressComplement !== null && conta.addressComplement !== undefined) {
      conta.addressComplement = conta.addressComplement.trim();
    }
    if (conta.addressNeighborhood !== null && conta.addressNeighborhood !== undefined) {
      conta.addressNeighborhood = conta.addressNeighborhood.trim();
    }
    if (conta.addressCity !== null && conta.addressCity !== undefined) {
      conta.addressCity = conta.addressCity.trim();
    }
    if (conta.addressState !== null && conta.addressState !== undefined) {
      conta.addressState = conta.addressState.trim();
    }
    if (conta.addressRegion !== null && conta.addressRegion !== undefined) {
      conta.addressRegion = conta.addressRegion.trim();
    }
    if (conta.whatsapp !== null && conta.whatsapp !== undefined) {
      conta.whatsapp = conta.whatsapp.trim().replace(/\D/g, "");
    }
    if (conta.fixedPhone !== null && conta.fixedPhone !== undefined) {
      conta.fixedPhone = conta.fixedPhone.trim().replace(/\D/g, "");
    }
    if (conta.instagram !== null && conta.instagram !== undefined) {
      conta.instagram = conta.instagram.trim();
    }
    if (conta.additionalEmails !== null && conta.additionalEmails !== undefined) {
      conta.additionalEmails = conta.additionalEmails.trim();
    }
    if (peopleType !== "F") {
      if (conta.fantasyName !== null && conta.fantasyName !== undefined) {
        conta.fantasyName = conta.fantasyName.trim();
      }
      if (conta.municipalInscription !== null && conta.municipalInscription !== undefined) {
        conta.municipalInscription = conta.municipalInscription.trim();
      }
      if (conta.stateInscription !== null && conta.stateInscription !== undefined) {
        conta.stateInscription = conta.stateInscription.trim();
      }
    } else {
      conta.fantasyName = "";
      conta.municipalInscription = "";
      conta.stateInscription = "";
    }

    if (conta.name === "") {
      addMessages(["Nome é obrigatório"]);
      return false;
    }
    if (conta.cpfCnpj === "") {
      addMessages(["CPF/CNPJ é obrigatório"]);
      return false;
    } else {
      if (peopleType === "F") {
        if (!validateCpf(conta.cpfCnpj)) {
          addMessages(["CPF inválido"]);
          return false;
        }
      } else {
        if (!validateCnpj(conta.cpfCnpj)) {
          addMessages(["CNPJ inválido"]);
          return false;
        }
      }
    }
    if (conta.addressPostalCode === "") {
      addMessages(["CEP do endereço é obrigatório"]);
      return false;
    }
    if (conta.addressNumber === "") {
      addMessages(["Número do endereço é obrigatório"]);
      return false;
    }

    if (conta.email !== "" && conta.email !== null) {
      if (!validateEmail(conta.email)) {
        addMessages(["Email inválido"]);
        console.log("Email inválido: " + conta.email);
        return false;
      }
    }

    console.log("Whatsapp: " + conta.whatsapp);
    if (conta.whatsapp !== null && conta.whatsapp !== undefined && conta.whatsapp !== "") {
      if (conta.whatsapp.length > 13 || conta.whatsapp.length < 12) {
        addMessages(["Whatsapp inválido"]);
        return false;
      }
    }
    console.log("Telefone: " + conta.fixedPhone);
    if (conta.fixedPhone !== null && conta.fixedPhone !== undefined && conta.fixedPhone !== "") {
      if (conta.fixedPhone.length > 13 || conta.fixedPhone.length < 12) {
        addMessages(["Telefone inválido"]);
        return false;
      }
    }
    return true;
  }

  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }
    let url = `v1/admin/accounts/edit/${contaId}`;
    const dados = conta;
    try {
      const response = await sendPost<IAccount>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/contas");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;

    let valor = type === "checkbox" ? target.checked : target.value;

    setConta((prevConta) => ({ ...prevConta, [name]: valor }));
  };

  const handleModuleChange = (event: React.ChangeEvent<HTMLInputElement>, module: IModule) => {
    const target = event.target as HTMLInputElement;
    let valor = target.checked;

    if (valor) {
      setConta((prevConta) => ({ ...prevConta, modules: [...prevConta.modules, module] }));
    } else {
      setConta((prevConta) => ({
        ...prevConta,
        modules: prevConta.modules.filter((module1) => module1.id !== module.id),
      }));
    }
  };

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>, plano: IPlan) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setConta((prevConta) => ({ ...prevConta, planos: [...prevConta.planos, plano] }));
    } else {
      setConta((prevConta) => ({
        ...prevConta,
        planos: prevConta.planos.filter((plan) => plan.id !== plano.id),
      }));
    }
  };

  async function consultaConta() {
    let url = `v1/admin/accounts/${contaId}`;
    const dados = {};
    try {
      const response = await sendGet<IAccount>(url, dados, addMessages, setaLoading, navigate);
      setConta(response);
      if (response.cpfCnpj.length > 11) {
        setPeopleType("J");
      } else {
        setPeopleType("F");
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaConta();
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Conta</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-buttons-group-people-type-label">Tipo Pessoa</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-people-type-label"
                    name="peopleType"
                  >
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      checked={peopleType === "F"}
                      label="Física"
                      disabled
                    />
                    <FormControlLabel
                      value="J"
                      control={<Radio />}
                      checked={peopleType === "J"}
                      label="Jurídica"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={peopleType === "F" ? "Nome" : "Razão Social"}
                  value={conta.name}
                  name="name"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              {peopleType === "J" ? (
                <Grid item xs={12}>
                  <TextField
                    label="Nome Fantasia"
                    value={conta.fantasyName}
                    name="fantasyName"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="CPF/CNPJ"
                  value={conta.cpfCnpj}
                  name="cpfCnpj"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={
                    peopleType === "F"
                      ? { inputComponent: CpfMaskCustom as any }
                      : { inputComponent: CnpjMaskCustom as any }
                  }
                  disabled
                />
              </Grid>
              {peopleType === "J" ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Municipal"
                      value={conta.municipalInscription}
                      name="municipalInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Estadual"
                      value={conta.stateInscription}
                      name="stateInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={conta.email}
                  name="email"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CEP"
                  value={conta.addressPostalCode}
                  name="addressPostalCode"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: CepMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rua"
                  value={conta.addressStreet}
                  name="addressStreet"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero"
                  value={conta.addressNumber}
                  name="addressNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  value={conta.addressComplement}
                  name="addressComplement"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bairro"
                  value={conta.addressNeighborhood}
                  name="addressNeighborhood"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cidade"
                  value={conta.addressCity}
                  name="addressCity"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Estado"
                  value={conta.addressState}
                  name="addressState"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Região"
                  value={conta.addressRegion}
                  name="addressRegion"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="WhatsApp"
                  value={conta.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: WhatsAppMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Celular"
                  value={conta.fixedPhone}
                  name="fixedPhone"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: PhoneMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Instagram"
                  value={conta.instagram}
                  name="instagram"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Emails Adicionais"
                  value={conta.additionalEmails}
                  name="additionalEmails"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conta.active || false}
                      onChange={handleChange}
                      name="active"
                      value={conta.active || false}
                    />
                  }
                  label="Ativa"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conta.bloqued || false}
                      onChange={handleChange}
                      name="bloqued"
                      value={conta.bloqued || false}
                    />
                  }
                  label="Bloqueada"
                />
              </Grid>
              <ModulesCheckboxGrid
                selectedModules={conta.modules}
                handleChange={handleModuleChange}
              />
              <PlanosCheckboxGrid
                planosSelecionados={conta.planos}
                handleChange={handlePlanChange}
              />

              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EdicaoContas;
