import { NavigateFunction } from "react-router-dom";
import { sendPost } from "../../../utils/httpUtils";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IInvoice } from "../../../types/Generic/IInvoice";

const invoiceService = {
    searchFilteredInvoice: async function (
        addMessages: (messages: string[]) => void,
        setaLoading: (loading: boolean) => void,
        navigate: NavigateFunction,
        page: number,
        itensPerPage: number,
        sort?: string,
        initialInvoiceDate?: Date,
        finalInvoiceDate?: Date,
        emitterId?: number,
        receiverId?: number,
        peopleId?: number,
        invoiceSerie?: string,
        invoiceNumber?: string,
        invoiceType?: string,
        searchQuery?: string,
      ) {
        let url = `v1/fis/invoice/list?size=${itensPerPage}&page=${page}${sort ? `&sort=${sort}` : ""}`;
        const dados = {
          page: page,
          itensPerPage: itensPerPage,
          sort: sort,
          initialInvoiceDate: initialInvoiceDate,
          finalInvoiceDate: finalInvoiceDate,
          search: searchQuery,
          emitterId: emitterId,
          receiverId: receiverId,
          peopleId: peopleId,
          invoiceSerie: invoiceSerie,
          invoiceNumber: invoiceNumber,
          invoiceType: invoiceType,
        };
        console.log(dados);
        try {
          const response = await sendPost<IPaginatedList<IInvoice>>(
            url,
            dados,
            addMessages,
            setaLoading,
            navigate,
          );
          console.log("response:", response);
          return response;
        } catch (error) {
          // Handle the error here if necessary
          console.log(error);
        }
      },
}


export default invoiceService;