import { Box, Modal, styled, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useEffect } from "react";

interface DebouncedTextAreaProps {
  defaultValue: string;
  debouncedValue?: string;
  onDebounce: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setValue?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setDebouncedValue?: (value: string) => void;
  debounceTime?: number;
  style?: any;
}

export default function DebouncedTextArea(props: DebouncedTextAreaProps) {

  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };
  const Textarea = styled("textarea")(
    ({ theme }) => `
    width: 700px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    height: 120px;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      outline: 0;
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );

  const debouncedHandleChange = useCallback(debounce(props.onDebounce, props.debounceTime || 700), []);

  return (
    <Textarea
        defaultValue={props.defaultValue}
        onChange={debouncedHandleChange}
        style={props.style}
  />
  );
}
