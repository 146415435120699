import { Box, Modal, TextField, Typography } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useEffect } from "react";

interface DebouncedTextFieldProps {
  value: string;
  debouncedValue: string;
  onDebounce: (value: string) => void;
  setValue: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  setDebouncedValue: (value: string) => void;
  debounceTime?: number;
  label?: string;
  variant?: "standard" | "outlined" | "filled";
  placeholder?: string;
  sx?: any;
}

export default function DebouncedTextField(props: DebouncedTextFieldProps) {

  let jaFez: boolean = false;
  
  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(
      debounce((value: string) => {
        props.setDebouncedValue(value);
        // Aqui você pode chamar sua função de busca ou lógica com value
        if (jaFez) {
          props.onDebounce(value);
        }
        jaFez = true;
  
        return;
      }, props.debounceTime || 1000),
      [],
    ); // 1000 ms de delay padrão

  useEffect(() => {
      // Atualiza o valor debounced toda vez que o inputValue mudar
        updateDebouncedSearchValue(props.value);
      return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
    }, [props.value, updateDebouncedSearchValue]);

  return (
   <TextField label={props.label || "Busca"} 
              variant={props.variant || "outlined"} 
              value={props.value} 
              onChange={(e) => props.setValue(e)} 
              placeholder={props.placeholder || "Digite para buscar..."}
              sx={props.sx}/>
  );
}
