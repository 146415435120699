import React, { useContext, useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ICapBill } from "../../../../types/Cap/Bill/ICapBill";
import { IBillUpdateData } from "../../../../types/Cap/Bill/ICapBillUpdateData";
import { ICapCategory } from "../../../../types/Cap/Category/ICapCategory";
import { ICreClient } from "../../../../types/Cre/Client/ICreClient";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import "./EditRecurrency.css";
import { ICapSuplier } from "../../../../types/Cap/Suplier/ICapSuplier";
import { resolve } from "path";
import { ICapPayment } from "../../../../types/Cap/Payment/ICapPayment";
import TableHeader from "../../../../../components/elements/TableHeader";
import CustomTableCell from "../../../../../components/elements/CustomTableCell";
import { formataDateLocal } from "../../../../utils/date-utils";
import { ISimpleList } from "../../../../types/Generic/ISimpleList";
import billsService from "../../../../services/admin/BillsService";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import NumericFormatCustom from "../../../../../components/elements/NumericFormatCustom";
import { IBankAccount } from "../../../../types/Ceb/IBankAccount";
import { ICapRecurrency } from "../../../../types/Cap/Recurrency/ICapRecurrency";
import { ICapRecurrencyUpdateData } from "../../../../types/Cap/Recurrency/ICapRecurrencyUpdateData";
import { ISimpleListStringString } from "../../../../types/Generic/ISimpleListStringString";
import recurrencyService from "../../../../services/admin/RecurrencyService";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const EditCapRecurrency = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const { id } = useParams();
  const recurrencyId = Number(id);

  const [recurrency, setRecurrency] = useState<ICapRecurrencyUpdateData>({
    id: 0,
    repeatEvery: 0,
    timeMeasure: "",
    projectionTime: 0,
    suplierId: 0,
    bankAccountId: 0,
    billDescription: "",
    firstBillDate: new Date(),
    firstDueDate: new Date(),
    categoryId: null,
    vlrPagar: null,
    active: true,
  } as ICapRecurrencyUpdateData);

  const [supliersList, setSupliersList] = useState<ICapSuplier[]>([] as ICapSuplier[]);

  const [timeMeasureList, setTimeMeasureList] = useState<ISimpleListStringString[]>(
    [] as ISimpleListStringString[],
  );

  const [categoryList, setCategoryList] = useState<ICapCategory[]>([] as ICapCategory[]);

  const [sort, setSort] = useState<string>("p.NAME,asc");

  const [bankAccountList, setBankAccountList] = useState<IBankAccount[]>([] as IBankAccount[]);

  const { selectedAccount, accounts } = useContext(GlobalContext);

  const [temModuloBankAccount, setTemModuloBankAccount] = useState<boolean>(false);

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
  }

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/cap/recurrency");
  }

  async function searchBankAccount(bankAccountName: string, bankAccountId: number) {
    let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: bankAccountName, id: bankAccountId };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setBankAccountList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
    console.log(bankAccountList);
  }

  async function searchRecurrency() {
    let url = `v1/cap/recurrency/${recurrencyId}`;
    const dados = {};
    try {
      const response = await sendGet<ICapRecurrency>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setRecurrency(response);
      searchPossibleTimeMeasureList();
      searchSupliers("", response.suplierId);
      searchCategories("", response.categoryId);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function handleClickSalvar() {
    let url = `v1/cap/recurrency/edit/${recurrencyId}`;
    console.log(url);
    const dados = {
      id: recurrency.id,
      repeatEvery: recurrency.repeatEvery,
      timeMeasure: recurrency.timeMeasure,
      projectionTime: recurrency.projectionTime,
      suplierId: recurrency.suplierId,
      bankAccountId: recurrency.bankAccountId,
      billDescription: recurrency.billDescription,
      firstBillDate: recurrency.firstBillDate,
      firstDueDate: recurrency.firstDueDate,
      categoryId: recurrency.categoryId,
      vlrPagar: recurrency.vlrPagar,
      active: recurrency.active,
    };
    console.log("dados");
    console.log(dados);
    try {
      const response = await sendPost<ICapRecurrency>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      navigate("/area-logada/cap/recurrency");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchSupliers(suplierName: string, suplierId: number) {
    let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
    const dados = { suplierName: suplierName, suplierId: suplierId };
    try {
      const response = await sendPost<IPaginatedList<ICapSuplier>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setSupliersList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchCategories(categoryName: string, categoryId: number | null) {
    let url = `v1/cap/category/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: categoryName, id: categoryId };
    try {
      const response = await sendPost<IPaginatedList<ICapCategory>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setCategoryList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setRecurrency((prevRecurrency) => ({ ...prevRecurrency, [name]: valor }));
  };

  async function searchPossibleTimeMeasureList() {
    const response = await recurrencyService.searchPossibleTimeMeasureList(
      addMessages,
      setaLoading,
      navigate,
    );
    if (response) {
      setTimeMeasureList(response);
    } else {
      addMessages(["Não houve resposta do serviço de recorrências"]);
    }
  }

  useEffect(() => {
    searchRecurrency();
    searchPossibleTimeMeasureList();
    const account = accounts.find((account) => account.accountId.toString() === selectedAccount);
    if (account) {
      searchBankAccount("", 0);

      setTemModuloBankAccount(false);

      const modulesToCheck = ["Caixas e Bancos"];
      const modules = account.modules;
      console.log(modules);
      if (modules.some((module) => modulesToCheck.includes(module.name))) {
        setTemModuloBankAccount(true);
        console.log("set true: " + temModuloBankAccount);
      } else {
        console.log(`Módulo bankAccount não encontrado com o selectedAccount ${selectedAccount}`);
      }
    } else {
      console.log(`Nenhuma conta encontrada com o selectedAccount ${selectedAccount}`);
    }
  }, [selectedAccount, accounts]);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Recorrência de Fatura à Pagar</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ display: "flex" }}>
                <NumericFormatCustom
                  prefix=""
                  decimalScale={0}
                  thousandSeparator=""
                  label="Repetir a cada"
                  value={recurrency.repeatEvery}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      repeatEvery: Number(values.target.value),
                    }))
                  }
                  name="repeatEvery"
                  style={{ width: 130 }}
                />
                &nbsp;&nbsp;
                <Autocomplete
                  disablePortal
                  id="combo-box-time-measure"
                  options={timeMeasureList}
                  getOptionLabel={(option) => option.label} // Use o nome da timeMeasure como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={
                    timeMeasureList.find(
                      (timeMeasure) => timeMeasure.id === recurrency.timeMeasure,
                    ) || null
                  } // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      timeMeasure: newValue?.id ? newValue.id : "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Unidade de tempo" required />
                  )}
                  style={{ width: 130 }}
                />{" "}
                &nbsp;&nbsp;{" "}
                <NumericFormatCustom
                  prefix=""
                  decimalScale={0}
                  thousandSeparator=""
                  label="Projeção de tempo"
                  value={recurrency.projectionTime}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      projectionTime: Number(values.target.value),
                    }))
                  }
                  name="projectionTime"
                  style={{ width: 130 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-suplier"
                  options={supliersList}
                  getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do cliente como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={
                    supliersList.find((suplier) => suplier.id === recurrency.suplierId) || null
                  } // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      suplierId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Fornecedor" required />}
                  onInputChange={(event, newInputValue) => {
                    searchSupliers(newInputValue, recurrency.suplierId);
                  }}
                />
              </Grid>
              {temModuloBankAccount && (
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-bank-account"
                    options={bankAccountList}
                    getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{ width: 300 }}
                    value={
                      bankAccountList.find(
                        (bankAccount) => bankAccount.id === recurrency.bankAccountId,
                      ) || null
                    } // Defina o valor padrão
                    onChange={(event, newValue) => {
                      setRecurrency((prevRecurrency) => ({
                        ...prevRecurrency,
                        bankAccountId: newValue ? newValue.id : 0,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Conta Bancária" />}
                    onInputChange={(event, newInputValue) => {
                      searchBankAccount(newInputValue, recurrency.bankAccountId);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="billDescription"
                  onChange={handleChange}
                  required
                  value={recurrency.billDescription}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Primeira Data da emissão"
                  value={recurrency.firstBillDate}
                  onChange={(newValue: Date | null) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      firstBillDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="firstBillDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Primeira Data de Vencimento"
                  value={recurrency.firstDueDate}
                  onChange={(newValue: Date | null) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      firstDueDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="firstDueDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-category"
                  options={categoryList}
                  getOptionLabel={(option) => option.name} // Use o nome da categoria como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={
                    categoryList.find((category) => category.id === recurrency.categoryId) || null
                  } // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setRecurrency((prevRecurrency) => ({
                      ...recurrency,
                      categoryId: newValue ? newValue.id : null,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Categoria" />}
                  onInputChange={(event, newInputValue) => {
                    searchCategories(newInputValue, recurrency.categoryId);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor a Pagar"
                  value={recurrency.vlrPagar}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      vlrPagar: Number(values.target.value),
                    }))
                  }
                  name="vlrPagar"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EditCapRecurrency;
